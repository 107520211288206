const dataAffectFamily = [
  {
    "id": "家族関係が悪化した",
    "家族関係が悪化した": 12
  },
  {
    "id": "家族と離ればなれになった",
    "家族と離ればなれになった": 20
  },
  {
    "id": "仕事を辞めた、変えた",
    "仕事を辞めた、変えた": 32
  },
  {
    "id": "家にこもりがちになった",
    "家にこもりがちになった": 46
  },
  {
    "id": "経済的に苦しくなった",
    "経済的に苦しくなった": 51
  },
  {
    "id": "心理的負担が増えた",
    "心理的負担が増えた": 52
  },
  {
    "id": "体調が悪くなる人が出た",
    "体調が悪くなる人が出た": 57
  },
  {
    "id": "ない",
    "ない": 70,
  },
  {
    "id": "近隣との交流がへった",
    "近隣との交流がへった": 102,
  }
]

const dataAffectFamilyD3 = [

  {
    "id": "近隣との交流がへった",
    "value": 102,
  },
  {
    "id": "ない",
    "value": 70,
  },
  {
    "id": "体調が悪くなる人が出た",
    "value": 57
  },
  {
    "id": "心理的負担が増えた",
    "value": 52
  },
  {
    "id": "経済的に苦しくなった",
    "value": 51
  },
  {
    "id": "家にこもりがちになった",
    "value": 46
  },
  {
    "id": "仕事を辞めた、変えた",
    "value": 32
  },
  {
    "id": "家族と離ればなれになった",
    "value": 20
  },
  {
    "id": "家族関係が悪化した",
    "value": 12
  },

]

export { dataAffectFamily, dataAffectFamilyD3 };