import React, {useEffect, useState} from "react";
import SectionText from "../../Reusable/SectionText/SectionText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faWindowClose, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
//import "./CurrentResidence.css"
import BarWithBoxes0 from "./graphs/BarWithBoxes0";
import BarWithBoxes1 from "./graphs/BarWithBoxes1";
import BarWithBoxes2 from "./graphs/BarWithBoxes2";
import BarWithBoxes3 from "./graphs/BarWithBoxes3";
import { Link } from "react-router-dom";



const sectionTitle = "本設へ、遺族の決断"
const SectionTextFiller = () => {
  return (
    <>
        現在のお住まいについてに関するさまざまな質問への回答を表すグラフ。      
        ドロップダウンメニューをクリックすると、質問を選択できます。 
        グラフの読み方のガイドについては、疑問符をクリックしてください。
    </>
  )
}

const GraphExplanation = ({closeExplanation}) => {
  return (
    <>
      <div className="normal-textbox">
        <FontAwesomeIcon icon={faWindowClose} 
          className="explanation-close" 
          onClick={closeExplanation}
         />
        四角をクリックすると、一人一人の回答が画面下部に表示されます。
      </div>
    </>
  )
}

// this is for the titles of each of the 4 graphs 
const title0 = "なぜここを本設の住居に選びましたか？"
const title1 = "お住まいの再建は当初の想定と比べて早かったですか、遅かったですか？"
const title2 = "現在のお住まいの地域は震災直後に思い描いていた地域ですか？"
const title3 = "現在お住まいの地域は震災前と比べて住みやすいですか？"
const titles = [title0, title1, title2, title3]



const CurrentResidence = ({ width, setSelectedGraphTitle }) => {

  const [revealGraphExplanation, setRevealGraphExplanation] = useState(false);
  // state for the selected section 
  const options = ['option0', 'option1', 'option2', 'option3']
  const [selectedSection, setSelectedSection] = useState('option0');

  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [])

  const toggleGraphExplanation = () => {
    setRevealGraphExplanation(!revealGraphExplanation)
  }

  // to change the selected section with dropdown 
  const changeSelection = e =>  setSelectedSection(e.target.value)

  return (
    <section className="section">

      <Link to={`/`}>
            <button 
              className="graph-close" 
              //onClick={() => setSelectedGraphTitle("homepage")}
            >
              <FontAwesomeIcon icon={faWindowClose}/>
            </button>  
      </Link> 

      <h3 className="section-title">{sectionTitle}</h3>

      <div className="currentresidence-dropdown">
          <select className="currentresidence-dropdown-select"
            onChange={e => changeSelection(e)}>
              <option value={options[0]}>{titles[0]}</option>
              {
               options
                ?options.slice(1).map((option, i) => (
                  <option value={option}>{titles[i+1]}</option>
                ))
                : null
              }
          </select>
        </div>  

        <Link to={"/移動先での困りごと（個別）"}>
          <FontAwesomeIcon className="back-arrow" icon={faChevronLeft}/>
        </Link>
        <Link to={"/本設での家族の暮らしの悩み"}>
          <FontAwesomeIcon className="forward-arrow" icon={faChevronRight}/>
        </Link>

      {
          selectedSection == 'option0' 
          ? <BarWithBoxes0 width={width}/> 
          : selectedSection == 'option1' 
          ? <BarWithBoxes1 width={width}/> 
          : selectedSection == 'option2'
          ? <BarWithBoxes2 width={width}/> 
          : selectedSection == 'option3'
          ? <BarWithBoxes3 width={width}/>
          : null
      }


    </section>
  )
};

export default CurrentResidence