import React, {useEffect, useState} from "react";
import SectionText from "../../Reusable/SectionText/SectionText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faWindowClose, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import PieCharts from "./graph/PieCharts";
import "./ComplaintsOverResidence.css";


const sectionTitle = "住居環境と困りごと"
const SectionTextFiller = () => {
  return (
    <>
      避難所・仮設・本設･･･悩みも変化   
    </>
  )
}

const GraphExplanation = ({closeExplanation}) => {
  return (
    <>
      <div className="normal-textbox">
        <FontAwesomeIcon icon={faWindowClose} 
          className="explanation-close" 
          onClick={closeExplanation}
         />
      </div>
    </>
  )
}



const ComplaintsOverResidence = ({ width }) => {

  const [revealGraphExplanation, setRevealGraphExplanation] = useState(false);

  const toggleGraphExplanation = () => {
    setRevealGraphExplanation(!revealGraphExplanation)
  }

  return (
    <section className="section">

      <Link to={`/`}>
          <button 
            className="graph-close" 
            //onClick={() => setSelectedGraphTitle("homepage")}
          >
            <FontAwesomeIcon icon={faWindowClose}/>
          </button>  
      </Link> 

      <h3 className="section-title">{sectionTitle}</h3>

      <SectionText>
        <SectionTextFiller/>
      </SectionText>

      <Link to={"/移動回数と困りごと"}>
        <FontAwesomeIcon className="back-arrow" icon={faChevronLeft}/>
      </Link>
      <Link to={"/移動先での困りごと（個別）"}>
        <FontAwesomeIcon className="forward-arrow" icon={faChevronRight}/>
      </Link>


    {/* 
      <button 
          className="graph-explain-icon" 
          onClick={() => setRevealGraphExplanation(true)}
        >
          <FontAwesomeIcon icon={faQuestion} />
      </button> 
      { revealGraphExplanation 
        ? <GraphExplanation closeExplanation={() => setRevealGraphExplanation(false)}/> 
        : null 
      } 
    */} 

      <div className="pie-charts-grid">
        <div className="pie-charts-item"><PieCharts issue={"生活条件"}/></div>
        <div className="pie-charts-item"><PieCharts issue={"災害の恐れ"}/></div>
        <div className="pie-charts-item"><PieCharts issue={"食料や水の確保"}/></div>
        <div className="pie-charts-item"><PieCharts issue={"買い物や通院など"}/></div>
        <div className="pie-charts-item"><PieCharts issue={"人間関係"}/></div>
      </div>

    </section>
  )
};

export default ComplaintsOverResidence