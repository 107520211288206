import img2 from "./img2.png"
import img3 from "./img3.png"
import img4 from "./img4.png"
import img5 from "./img5.png"
import img6 from "./img6.png"
import img7 from "./img7.png"
import img8 from "./img8.png"
import img9 from "./img9.png"
import img10 from "./img10.png"
import mapwebsite from "./map_website.png"


// All the titles 
const title1 = "避難所、仮設･･･流転の日々";
const title2 = "移動回数と困りごと";
const title3 = "住居環境と困りごと"
const title4 = "移動先での困りごと（個別）";

const title5 = "本設へ、遺族の決断";
const title6 = "本設での家族の暮らしの悩み";

const title7 = "居住、滞在先での気持ち"
const title8 = "震災でお亡くなりになられた方々への思い"
const title9 = "大切な人へ"
const title10 = "あなたをさがして"


const imagesSection1 = [
  //{ img: img1, title: title1,},
  {
    img: img2,
    title: title2,
  },
  {
    img: img3,
    title: title3,
  },
  {
    img: img4,
    title: title4,
  },
];

const imagesSection2 = [
  {
    img: img5,
    title: title5,
  },
  {
    img: img6,
    title: title6,
  },
];

const imagesSection3 = [
  {
    img: img7,
    title: title7,
  },
  {
    img: img8,
    title: title8,
  },
  {
    img: img9,
    title: title9,
  },
  {
    img: img10,
    title: title10,
  },
];

const imagesSection4 = [
  {
    img: mapwebsite,
    title: "",
  },
];


export { imagesSection1, imagesSection2, imagesSection3, imagesSection4 }