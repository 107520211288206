import React, {useEffect, useState} from "react";
import SectionText from "../../Reusable/SectionText/SectionText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faWindowClose, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import NightingaleSemi from "./graph/NightingaleSemi";
import BarChart from "./graph/BarChart"
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";



const sectionTitle = "居住・滞在先における感情"
const SectionTextFiller = () => {
  return (
    <>
      被災者を思い出して感情が揺さぶられたり、心身が変化することがありましたか？
    </>
  )
}

const GraphExplanation = ({closeExplanation}) => {
  return (
    <>
      <div className="normal-textbox">
        <FontAwesomeIcon icon={faWindowClose} 
          className="explanation-close" 
          onClick={closeExplanation}
         />
        各移動先での心境について、グラフに表しました。一人が複数の避難所や仮設住宅を移動しているケースがあり、延べ回答数を集計しました。
      </div>
    </>
  )
}



const NumberMoves = ({ width, graph, setSelectedGraphTitle }) => {

  const [revealGraphExplanation, setRevealGraphExplanation] = useState(false);

  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [])

  const toggleGraphExplanation = () => {
    setRevealGraphExplanation(!revealGraphExplanation)
  }

  return (
    <section className="section">

      <Link to={`/`}>
          <button 
            className="graph-close" 
            //onClick={() => setSelectedGraphTitle("homepage")}
          >
            <FontAwesomeIcon icon={faWindowClose}/>
          </button>  
      </Link> 

      <h3 className="section-title">{sectionTitle}</h3>

      <SectionText>
        <SectionTextFiller/>
      </SectionText>

      <Link to={"/本設での家族の暮らしの悩み"}>
        <FontAwesomeIcon className="back-arrow" icon={faChevronLeft}/>
      </Link>
      <Link to={"/震災でお亡くなりになられた方々への思い"}>
        <FontAwesomeIcon className="forward-arrow" icon={faChevronRight}/>
      </Link>

      <button 
          className="graph-explain-icon" 
          //onMouseEnter={() => isMobile ? console.log("mobile") : setRevealGraphExplanation(true)}
          //onMouseLeave={() => isMobile ? console.log("mobile") : setRevealGraphExplanation(false)}
          onClick={() => setRevealGraphExplanation(true)}
        >
          <FontAwesomeIcon icon={faQuestion} />
      </button> 
      { revealGraphExplanation 
        ? <GraphExplanation closeExplanation={() => setRevealGraphExplanation(false)}/> 
        : null 
      } 

      {
        graph == "bar"
        ? <BarChart width={width} />
        : <NightingaleSemi width={width}/>
          
      }

    </section>
  )
};

export default NumberMoves