import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import "./Header.css"

const Header = () => {

  return (
    <section id="header-section">
      <div className="header-title-image"></div>
      <div className="header-arrow-down bounce">
      <FontAwesomeIcon icon={faAngleDoubleDown}/>
      </div>
    </section>
  )
}

export default Header;