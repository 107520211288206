import React, {useEffect, useState} from "react";
import "./HomePage.css";
import { imagesSection1, imagesSection2, imagesSection3, imagesSection4 } from "./data/imageImports";

import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from "react-router-dom";

/// Section names ///

const nameSection1 = "第１章　避難所、仮設･･･流転の日々"
const nameSection2 = "第２章　住まいの決断"
const nameSection3 = "第３章　あの人を思う"



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "#f6f2e7",
    minWidth: 280,
    width: '90%',
  },
  image: {
    position: 'relative',
    height: 170,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
  },
  gridList: {
  },
  gridListTileBar: {
    backgroundColor: "#242423",
    opacity: 0.6,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.9)',
  },
}));

function TitlebarGridList({ tileData, numCols, setSelectedGraphTitleProp }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList cellHeight={170} cols={numCols} className={classes.gridList}>
        {
          tileData.map((tile) => (
            <GridListTile key={tile.img} className={classes.image}>
              <img src={tile.img} alt={tile.title} />
              <GridListTileBar
                className={classes.gridListTileBar}
                title={tile.title}
                actionIcon={
                  <Link to={`${tile.title}`}  >
                    <IconButton 
                      aria-label={`${tile.title}`} 
                      className={classes.icon}
                      //onClick={(e) => {setSelectedGraphTitleProp(tile.title)}}
                      >
                      <OpenInNewIcon />
                    </IconButton>
                  </Link>
                }
              />
            </GridListTile>
          ))
        }
      </GridList>
    </div>

  );
}


function TitlebarMap({ tileData, numCols }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList cellHeight={170} cols={numCols} className={classes.gridList}>
        {
          tileData.map((tile) => (
            <GridListTile key={tile.img} className={classes.image}>
              <img src={tile.img} alt={tile.title} />
              <GridListTileBar
                className={classes.gridListTileBar}
                title={tile.title}
                actionIcon={
                  <IconButton 
                    aria-label={`${tile.title}`} 
                    className={classes.icon}
                    onClick={(e) => window.open('https://311stories.archiving.jp/')
                  }>
                    <OpenInNewIcon />
                  </IconButton>
                }
              />
            </GridListTile>
          ))
        }
      </GridList>
    </div>

  );
}




const HomePage = ({ setSelectedGraphTitle }) => {

  return (
    <section className="section-homepage">

      <h3 className="homepage-section-title">{nameSection1}</h3>
      <p className="homepage-section-description">
        津波で住まいを失った人たちは、何度も移動を繰り返し、年月をかけて終のすみかにたどり着いた。被災直後に多くの人が直面したのは、避難所での生活条件に関する悩みだ。時間の経過とともに環境は改善されたが、移動が重なることで、人と人とのつながりが希薄になるという新たな課題も出てきた。
      </p>
      <TitlebarGridList 
        tileData={imagesSection1} 
        numCols={3}
      />
      <br />
      <br />

      <h3 className="homepage-section-title">{nameSection2}</h3>
      <p className="homepage-section-description">
        被災者の復興のかたちは、一つとして同じものはない。本設再建に至るまでの一人一人の決断に迫ると、大規模な宅地整備に時間がかかる中、思い描いた再建とのギャップが浮き彫りとなった。
      </p>
      <TitlebarGridList 
        tileData={imagesSection2} 
        numCols={2}
      />
      <br />
      <br />

      <h3 className="homepage-section-title">{nameSection3}</h3>
      <p className="homepage-section-description">
      暮らしを立て直そうと必死に歩んだ遺族の１０年は、常に亡き人とともにあった。大切なあの人へ、いま伝えたい思いをつづる。
      </p>
      <TitlebarGridList 
        tileData={imagesSection3} 
        numCols={4}
      />

      <br />
      <br />
      <h3 className="homepage-section-title">忘れない 震災遺族10年の軌跡 | マップ</h3>
      <TitlebarMap 
        tileData={imagesSection4} 
        numCols={1}
      />

    </section>
  )
};

export default HomePage