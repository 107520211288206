import React, { useState, useEffect, useLayoutEffect } from "react";
import "./MissingPeopleWordcloud.css";
import WordCloud from "./graph/WordCloud"
import { LetterCardSmallMissingPeopleWordcloud } from "../../Reusable/LetterCard/LetterCard"
import lettersMissing from "../../../data/missing-people-wordcloud_missing_yes.json"
import lettersNoMissing from "../../../data/missing-people-wordcloud_missing_no.json"
import { dataMissing, dataNoMissing } from "../../../data/missingPeopleWordcloud"
import _ from "lodash";
import SectionText from "../../Reusable/SectionText/SectionText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faWindowClose, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";



const sectionTitle = "あなたをさがして"


/// Top positive and negative words for missing people /// 
const topWordsOnlyPositiveMissing = [
'暮らす','孫','見る','震災','地域','安心','楽しい','おかげ','感謝','毎日',
'仮設','父','元気'
]

const topWordsOnlyNegativeMissing = [
'逃げる','建てる','考える','行方','若い','亡くす','後悔','津波','ほしい','不明',
'一緒','定年','年'
]


/// Top positive and negative words for no missing people ///
const topWordsOnlyPositiveNoMissing = [
'今', '人', '元気', '感謝', '伝える', '家族','良い', '子ども', '暮らす', '夫',
 '生活',  '孫', '心配'
]

const topWordsOnlyNegativeNoMissing = [
'避難', '逃げる', '津波', '思い', '犠牲', '戻る', '分かる', '残念', '父さん', '前', 
'家', '気持ち', '母'
]


// filter out the letters of the peole who don't want name and photo displayed 

// min and max sentiment on which to filter 
const minPositiveSentiment = 0.25
const maxNegativeSentiment = -0.25

const SectionTextFiller = () => {
  return (
    <>
      亡くなった方々の思いを聞かせてください
    </>
  )
}


const MissingPeopleWordCloud = ({ width, setSelectedGraphTitle }) => {

  // the selected lemma for people with missing family
  const [selectedLemmaMissing, setSelectedLemmaMissing] = useState(null)
  // the selected lemma for people without missing family
  const [selectedLemmaNoMissing, setSelectedLemmaNoMissing] = useState(null)


  return (
    <section className="section section-missingpeople">

      <Link to={`/`}>
          <button 
            className="graph-close" 
            //onClick={() => setSelectedGraphTitle("homepage")}
          >
            <FontAwesomeIcon icon={faWindowClose}/>
          </button>  
      </Link> 

      <h3 className="section-title">{sectionTitle}</h3>

      <SectionText>
        <SectionTextFiller/>
      </SectionText>

      <Link to={"/大切な人へ"}>
        <FontAwesomeIcon className="back-arrow" icon={faChevronLeft}/>
      </Link>
      
      <br />
      <div style={{'font-size': '12px'}}>＊ 色付きの単語をクリック・タップ：個別の回答表示</div> 
      <br />

      <h3 className="wordcloud-missing-people-section-title">行方不明の家族あり</h3>
      <div className="container-missing-people-wordcloud">
        {/* Word Cloud for missing people */}
        <WordCloud 
          width={width}
          setSelectedLemma={setSelectedLemmaMissing} 
          topWordsOnlyPositive={topWordsOnlyPositiveMissing}
          topWordsOnlyNegative={topWordsOnlyNegativeMissing}
          data={dataMissing}
        />
        {/* selecting the lemma from the wordcloud for missing people */}
        {selectedLemmaMissing  ?
          <div className="letter-cards-missing-people-wordcloud" >
            { lettersMissing.filter(el => el.lemma == selectedLemmaMissing)[0] ?
            // filter the letters for the selected lemma; then if the lemma is in the 
            // list of positive words, display only answers with positive sentiment above 
            // threshold; if the lemma is in the list of negative words, display only the 
            // answer with sentiment below threshold; otherwise don't display answers
              _.filter(
                lettersMissing.filter(el => el.lemma == selectedLemmaMissing)[0]['answers'],
                function(answer) {
                  return topWordsOnlyPositiveMissing.includes(selectedLemmaMissing)
                  ? answer.sentiment_google >= minPositiveSentiment 
                  : topWordsOnlyNegativeMissing.includes(selectedLemmaMissing) 
                  ? answer.sentiment_google <= maxNegativeSentiment
                  : null
                })
                .map((answer, i) => (
                <LetterCardSmallMissingPeopleWordcloud
                  key={i}
                  letter={answer}
                />
              ))
              : null
            }
          </div>
          :null
        }
      </div>

      <h3 className="wordcloud-missing-people-section-title">行方不明の家族なし</h3>
      <div className="container-missing-people-wordcloud">
        {/* Word Cloud for no missing people */}
        <WordCloud 
          width={width}
          setSelectedLemma={setSelectedLemmaNoMissing} 
          topWordsOnlyPositive={topWordsOnlyPositiveNoMissing}
          topWordsOnlyNegative={topWordsOnlyNegativeNoMissing}
          data={dataNoMissing}
        />

        {/* selecting the lemma from the wordcloud for no missing people */}
        {selectedLemmaNoMissing  ?
          <div className="letter-cards-missing-people-wordcloud" >
            { lettersNoMissing.filter(el => el.lemma == selectedLemmaNoMissing)[0] ?
            // filter the letters for the selected lemma; then if the lemma is in the 
            // list of positive words, display only answers with positive sentiment above 
            // threshold; if the lemma is in the list of negative words, display only the 
            // answer with sentiment below threshold; otherwise don't display answers
              _.filter(
                lettersNoMissing.filter(el => el.lemma == selectedLemmaNoMissing)[0]['answers'],
                function(answer) {
                  return topWordsOnlyPositiveNoMissing.includes(selectedLemmaNoMissing)
                  ? answer.sentiment_google >= minPositiveSentiment
                  : topWordsOnlyNegativeNoMissing.includes(selectedLemmaNoMissing) 
                  ? answer.sentiment_google <= maxNegativeSentiment
                  : null
                })
                .map((answer, i) => (
                <LetterCardSmallMissingPeopleWordcloud
                  key={i}
                  letter={answer}
                />
              ))
              : null
            }
          </div>
          :null
        }

      </div>

    </section>
  )
};





export default MissingPeopleWordCloud