import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import _ from "lodash";
import complaintsByResidenceType from "../../../../data/complaintsByResidenceType.csv";  
import chroma from "chroma-js";

const cremeWhite = "#f6f2e7"
const lightGrey = '#565050'

///////////////////////////////////////////////
////////  PIE CHART W/ RESIDENCE TYPE /////////
///////////////////////////////////////////////
const PieCharts = ({ issue }) => {
  /// refs ///
  const svgRef = useRef();
  const gPieRef = useRef();

  /// states ///
  const [allData, setAllData] = useState(null);
  const [issues, setIssues] = useState(null);

  /// dimensions ///
  const width = 320;
  const height = width;
  const innerRadius = width/5;
  const outerRadius = width/3.2;
  const padAngle = 0.15;
  const cornerRadius = 3;


  /// Data load ///
  useEffect(() => {
    // manually set the issues - easiest 
    const issues = ['生活条件', '食料や水の確保', '災害の恐れ', '買い物や通院など', '人間関係']
    setIssues(issues)
    d3.csv(complaintsByResidenceType, d3.autoType).then(d => {
      // save the whole data into state 
      setAllData(d)
    })
  }, [])

  // helper function for getting data for 1 issue
  // and transforming it into the format for the pie 
  const dataForIssue = (issue) => {
    const dataForIssue = _.filter(allData, issueEntry => issueEntry["issue_classification"] == issue)
    /// Transform the data for pie ///
    const dataPieTransform  = {
      避難所: dataForIssue[0]["1"],
      仮住まい: dataForIssue[0]["2"],
      本設住宅: dataForIssue[0]["3"]
    }
    let dataPie = []
    for (const [residence, count] of Object.entries(dataPieTransform)) {
      dataPie.push({
        name: residence,
        value: count
      })
    };
    return dataPie
  }

  /// D3 code ///
  useEffect(() => {
    if (allData) {

      //const issue = "人間関係"
      const data = dataForIssue(issue)

      const svg = d3.select(svgRef.current)

      /// colour scale ///
      const colorScale = d3.scaleOrdinal()
        .domain(['避難所', '仮住まい', '本設住宅'])
        .range(['#B76935', '#263C41', '#22596D'])

      //////////////////
      /// Pie Chart ////
      //////////////////
      const gPie = d3.select(gPieRef.current)
      .attr("transform", `translate(${width/2}, ${height/2})`)

      /// 1. Pie ///
      const pie = d3.pie()
        .sort(null)
        .value(d => d.value)
      const arcs = pie(data)

      /// 2. Arc ///
      const arc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .padAngle(padAngle)
        .cornerRadius(cornerRadius)

      /// 3. Pie chart ///
      const pieChart = gPie
        .selectAll(".pie-path") 
        .data(arcs)
        .join("path")
        .classed("pie-path", true)
          .attr("stroke", d => colorScale(d.data.name))
          .attr("stroke-opacity", 0.8)
          .attr("stroke-width", 5) 
          .attr("fill", d => colorScale(d.data.name))
          .attr("fill-opacity", 1)
          .attr("d", arc)

      const pieChartMiddle= svg
        .selectAll(".pie-path-middle-text") 
        .data([issue])
        .join("text")
        .classed("pie-path-middle-text", true)
        .attr("transform", `translate(${width/2}, ${height/2})`)
          .style("fill", lightGrey)
          .attr("text-anchor", "middle")
          .text(d => d)
          .attr("dy", "0.35em")

      /// . Pie chart Labels ///
      const radius = Math.min(width, height) / 2 
      const arcLabel = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius*2)
      // Text on the pie chart 
      const pieChartText = gPie
          .selectAll(".pie-text")
          .data(arcs)
          .join("text")
          .classed("pie-text", true)
            .attr("font-family", "sans-serif")
            .attr("text-anchor", "middle")
            .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
            .call(text => text
              .selectAll(".tspan-1").data(d => [d]).join("tspan").classed("tspan-1", true)
                  .attr("dy", "0.35em")
                  .attr("fill-opacity", 0.8)
                  .attr("fill", lightGrey)
                  .text(d => `${d.data.name}`))
            .call(text => text
              .selectAll(".tspan-2").data(d => [d]).join("tspan").classed("tspan-2", true)
                .attr("dy", "1em")
                .attr("dx", "-3em")
                .attr("text-anchor", "middle")
                .attr("fill-opacity", 0.6)
                .attr("fill", lightGrey)
                .attr("font-size", "1em")
                .text(d => `${d.data.value}回`))

    }
  }, [allData])

  return (
    <>
      <svg ref={svgRef} width={width} height={height}>
        <g ref={gPieRef}></g>
      </svg>
    </>
  )
}

export default PieCharts;

