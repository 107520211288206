import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import * as _ from "lodash";
import "../NumberMoves.css";
import { data_all, data_missing, data_nomissing, data_all_year_prop, data_all_year_num, data_all_residence } from "../../../../data/num_moves.js";

const NightingaleSemi = ({ width }) => {
  const svgRef = useRef();
  const xAxisRef = useRef();
  const yAxisRef = useRef();
  const legendRef = useRef();
  const graphRef = useRef();

  // VARIABLES //
  //const width = 800;
  const height = width/2;
  const innerRadius =  width > 650?  height/2.4 : height/2.2;
  const outerRadius = height;
  let margin = {top: 10, bottom: 10, left: 10}
  if (width > 700) {
    margin.right = 10
  } else {
    margin.right = 60
  }


  // Color options 
  const colors1 = ["#250902", "#640d14", "#175676", "#4ba3c3"]
  const colors2 = ['#C87741', '#815839','#263C41', '#22596D']


  // Data variables used in chart 
  //const chronologyColumn = "MoveNumber" // by number of moves 
  const chronologyColumn = "ResidenceType" // by residence

  // const [data, setData] = useState(data_all) // by number of moves 
  const [data, setData] = useState(data_all_residence) // by residence

  useEffect(() => {

    // SCALES //
    const x = d3.scaleBand()
      .domain(data.map(d => d[chronologyColumn]))
      .range([-0.5 * Math.PI, 0.5 * Math.PI])
      .align(0.5)
      .padding(0.1)
    const y = d3.scaleRadial()
      .domain([0, d3.max(data, d => d.total)])
      //.domain([0, 420]) // by number of move
      .range([innerRadius, outerRadius])

    const z = d3.scaleOrdinal()
      .domain(data.columns.slice(1))
      .range(colors2)


    // AXES //
    // X Axis 
    const xAxis = g => g
      .attr("transform", `translate(${width/2}, ${height})`)
      .attr("text-anchor", "middle")
      .call(g => g.selectAll("g")
        .data(data, d => d['少しあった'])
        .join("g")
        .attr("transform", d => `
          rotate(${((x(d[chronologyColumn]) + x.bandwidth() / 2) * 180 / Math.PI - 90)})
          translate(${innerRadius+7},0)
        `)
        .call(g => g
          .selectAll(".xaxis-line")
          .data(d => [d])
          .join("line")
          .classed("xaxis-line", true)
          .attr("x2", 0)
          .style("stroke", "red"))
        .call(g => g
          .selectAll(".xaxis-text")
          .data(d => [d])
          .join("text")
          .classed("xaxis-text", true)
          .attr("transform", d => (x(d[chronologyColumn]) + x.bandwidth() / 2 + Math.PI / 2) % (Math.PI) < 0.5 * Math.PI
            ? "rotate(180)translate(35,3)"
            : "rotate(0)translate(-35,3)")
          .text(d => 
                //("住居").concat(d[chronologyColumn]) // if my move number 
                d[chronologyColumn] == 1 ? "避難所" : d[chronologyColumn] == 2 ? "仮住まい" : "本設住宅" // if by residence type
              )))

              

    // Y Axis 
    const yAxis = g => g
      .attr("transform", `translate(${width/2}, ${height})`)
      .attr("text-anchor", "middle")
      .call(g => g.selectAll("g")
        .data(width > 500 ? y.ticks(4).slice(1) : y.ticks(2).slice(1) , 
        d => d*Math.random())
        .join("g")
        .attr("fill", "none")
        .call(g => g.append("path")
          .attr("stroke", "white")
          .attr("stroke-opacity", 0.8)
          .attr("d", d3.arc()
            .innerRadius(y)
            .outerRadius(y)
            .startAngle(-0.5 * Math.PI)
            .endAngle(0.5 * Math.PI)))
        .call(g => g
          .selectAll(".yaxis-text")
          .data(d => [d])
          .join('text')
          .classed("yaxis-text", true )
          .attr("x", d => -y(d))
          .attr("dy", "0.35em")
          //.text(d => d.toString().concat("人")) // if by move number
          .text(d => d.toString().concat("回")) // if by type of residence
          .clone(true)
          .attr("fill", "#000")
          .attr("stroke", "none"))
      )

    // Legend 
    const legend = g => g
      .attr("transform", `translate(${
        width < 700 ? width - 20 : width/2
      }, ${
        width < 700 ? height/1.8 : height
      })`)
      .selectAll("g")
      .data(data.columns.slice(1).reverse())
      .join("g")
      .attr("transform", (d, i) => `translate(${-40},${(i - (data.columns.length - 1) / 2) * 20 - 40})`)
      .call(g => g
        .selectAll(".nightingale-legend-rect")
        .data(d => [d])
        .join("rect")
        .classed("nightingale-legend-rect", true)
          .attr("width", width <= 700 ? 15 : 20)
          .attr("height", width <= 700 ? 15 : 20)
          .attr("fill", z))
      .call(g => g
        .selectAll(".nightingale-legend-text")
        .data(d => [d])
        .join("text")
        .classed("nightingale-legend-text", true)
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", "0.35em")
          .text(d => d))

    // GRAPH //
    const t = d3.transition().duration(1000);

    const arc = d3.arc()
      .innerRadius(d => y(d[0]))
      .outerRadius(d => y(d[1]))
      .startAngle(d => x(d.data[chronologyColumn]))
      .endAngle(d => x(d.data[chronologyColumn]) + x.bandwidth())
      .padRadius(innerRadius)

    const svg = d3.select(graphRef.current)
      .attr("transform", `translate(${width/2}, ${height})`)

    svg
      .selectAll("g")
      .data(d3.stack().keys(data.columns.slice(1))(data))
      .join("g")
      .attr("fill", d => z(d.key))
      .selectAll("path")
      .data(d => d)
      .join("path")
      .transition(t)
        .attr("d", arc)


    d3.select(xAxisRef.current).call(xAxis)
    d3.select(yAxisRef.current).call(yAxis)
    d3.select(legendRef.current).call(legend)

  }, [data, width])

  // change the data to that of people w missing people on button click 
  function changeToMissing(){
    setData(data_missing)
  }

  // change the data to that of people without missing people on button click 
  function changeToNoMissing(){
    setData(data_nomissing)
  }

  // change the data to that of all people
  function changeToAll(){
    setData(data_all)
  }

  return (
      <div className="nightingale-container">
        <svg
            ref={svgRef}
            width={width + margin.right + margin.left}
            height={height + margin.bottom + margin.top}
          >
            <g className="graph" ref={graphRef}></g>
            <g className="x-axis" ref={xAxisRef}></g>
            <g className="y-axis" ref={yAxisRef}></g>
            <g className="legend" ref={legendRef}></g>
        </svg>
        <br/>
        {/* Buttons for filtering based on presence or absence of missing family
        <button onClick={changeToMissing} className="nightingale-button">行方不明家族あり</button>
        <button onClick={changeToNoMissing} className="nightingale-button">行方不明家族なし</button>
        <button onClick={changeToAll} className="nightingale-button">両方</button>
         */}

      </div>
  )
};

export default NightingaleSemi;