import React, {useEffect, useState} from "react";
import SectionText from "../../Reusable/SectionText/SectionText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faWindowClose, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import BarWithBoxes from "./graph/BarWithBoxes";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";




const sectionTitle = "大切な人へのメッセージ"
const SectionTextFiller = () => {
  return (
    <>
      亡くなられた方々への思いを聞かせてください
    </>
  )
}

const GraphExplanation = ({closeExplanation}) => {
  return (
    <>
      <div className="normal-textbox">
        <FontAwesomeIcon icon={faWindowClose} 
          className="explanation-close" 
          onClick={closeExplanation}
         />
          震災犠牲者へのメッセージの対象となる相手の続柄を分類したグラフです。
      </div>
    </>
  )
}



const PeopleMentions = ({ width, animate }) => {

  const [revealGraphExplanation, setRevealGraphExplanation] = useState(false);

  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [])

  const toggleGraphExplanation = () => {
    setRevealGraphExplanation(!revealGraphExplanation)
  }

  return (
    <section className="section">

      <Link to={`/`}>
          <button 
            className="graph-close" 
            //onClick={() => setSelectedGraphTitle("homepage")}
          >
            <FontAwesomeIcon icon={faWindowClose}/>
          </button>  
      </Link> 

      <h3 className="section-title">{sectionTitle}</h3>

      <SectionText>
        <SectionTextFiller/>
      </SectionText>

      <Link to={"/震災でお亡くなりになられた方々への思い"}>
        <FontAwesomeIcon className="back-arrow" icon={faChevronLeft}/>
      </Link>
      <Link to={"/あなたをさがして"}>
        <FontAwesomeIcon className="forward-arrow" icon={faChevronRight}/>
      </Link>

      <button 
          className="graph-explain-icon" 
          //onMouseEnter={() => isMobile ? console.log("mobile") : setRevealGraphExplanation(true)}
          //onMouseLeave={() => isMobile ? console.log("mobile") : setRevealGraphExplanation(false)}
          onClick={() => setRevealGraphExplanation(true)}
        >
          <FontAwesomeIcon icon={faQuestion} />
      </button> 
      { revealGraphExplanation 
        ? <GraphExplanation closeExplanation={() => setRevealGraphExplanation(false)}/> 
        : null 
      }  


      <BarWithBoxes width={width} animate={animate}/>
      <br />

    </section>
  )
};

export default PeopleMentions