import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { dataAffectFamilyD3 } from "../../../../data/dataTopics";
import chroma from "chroma-js";


/*
const colourMapping = {
  "家族関係が悪化した": "#143642",
  "家族と離ればなれになった": "#263c41",
  "仕事を辞めた、変えた": "#38413f",
  "家にこもりがちになった": "#4a473e",
  "経済的に苦しくなった": "#5c4d3c",
  "心理的負担が増えた": "#6f523b",
  "体調が悪くなる人が出た": "#935e38",
  "ない": "#a56336",
  "近隣との交流がへった": '#b76935'
}
*/

const issues = ["家族関係が悪化した", "家族と離ればなれになった", "仕事を辞めた、変えた",
"家にこもりがちになった", "経済的に苦しくなった", "心理的負担が増えた", "体調が悪くなる人が出た", "ない", "近隣との交流がへった"]
const colours = chroma.scale(['#C87741', '#815839', '#263C41', '#22596D']).mode('rgb').colors(9)
var colourMapping = {};
issues.forEach((issue, i) => colourMapping[issue] = colours[i]);


const BarChart = ({ onClick, width }) => {

  const svgRef = useRef();
  const gRef = useRef();
  const textRef = useRef();

  const [data, setData] = useState(null);

  /// Dimensions ///
  //const width = 350;
  const height = 530;
  const colourText = "#f6f2e7"


  useEffect(() => {
    setData(dataAffectFamilyD3)
  }, [])


  /// d3 code ///
  useEffect(() => {
    if (data) {
      /// Scales ///
      // X Scale 
      const x = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.value)])
        .range([0, width])
      // Y Scale
      const y = d3.scaleBand()
        .domain(data.map(d => d.id))
        .rangeRound([0, height])
        .padding(0.1)

      const t = d3.transition().duration(1000)

      /// Graph ///
      const svg = d3.select(svgRef.current)
          .attr("height", height)
          .attr("width", width)
      
      const graphG = d3.select(gRef.current)

      const graph = graphG
        .selectAll(".graph-group")
        .data(data)
        .join("g")
        .classed("graph-group", true)
          .attr("cursor", 'default')
          .attr("font-family", "sans-serif")
          


      const graphRect = graph
        .selectAll("rect")
        .data(d => [d])
        .join("rect")
          .attr("fill", d => colourMapping[d.id])
          .attr("x", x(0))
          .attr("y", (d, i) => y(d.id))
          .attr("height", y.bandwidth())
          .attr("width", 0)
          .attr("rx", 10)
          .transition(t)
            .attr("x", x(0))
            .attr("width", d => x(d.value) - x(0))


      const graphTextNumbers = graph
          .selectAll('.text-numbers')
          .data(d => [d])
          .join("text")
          .classed("text-numbers", true)
            .style("fill", colourText)
            .attr("text-anchor", "end")
            .style("font-size", "12px")
            .attr("dx", -10)
            .attr("dy", "0.35em")
            .attr("x", d => x(d.value))
            .attr("y", (d, i) => y(d.id) + y.bandwidth() / 2)
            .text(d => d.value)
            .attr("opacity", 0)
            .transition(t)
              .attr("opacity", 1)

      const graphTextText = graph
          .selectAll(".text-text")
          .data(d => [d])
          .join("text")
          .classed("text-text", true)
            .style("fill", colourText)
            .style("font-size", "12px")
            .attr("x", d => x(0))
            .attr("y", (d, i) => y(d.id) + y.bandwidth() / 2)
            .attr("dx", 15)
            .attr("dy", "0.35em")
            .text(d => d.id)
            .attr("opacity", 0)
            .transition(t)
              .attr("opacity", 1)
          .call(text => text.filter(d => x(d.value) - x(0) < 160)
            .attr("dx", d => x(d.value) + 15)
            .style("fill", '#565050')
          );


        /////////////////////////
        /////// Events //////////
        /////////////////////////

        graph.on("click", function(e, datum) {
            onClick(datum)
            graph.attr("fill-opacity", d => d == datum ? 1 : 0.2)
            //graphTextNumbers.attr("opacity", d => d == datum ? 1 : 0.2)
            //graphTextText.attr("opacity", d => d == datum ? 1 : 0.2)
          })

        // for the event where we restore the opacity when we click outisde the legend 
        svg
          .on("click",function(e, datum){
            // check if the click ocurred on the target or not 
            // if it's "false" then the click did not happen on the target 
            if (this == e.target) {
              graph.attr("fill-opacity", 1)
              //graphTextNumbers.attr("opacity", 1)
              //graphTextText.attr("opacity", 1)
              onClick("no topic")
            }
        });
    } 
  }, [data, width])


  return (   
      <div>
        <svg ref={svgRef}>
          <g ref={gRef}></g>
          <g ref={textRef}></g>
        </svg>
      </div>
  )
};

export default BarChart