import React, {useEffect} from "react";


const SectionText = (props) => {

  return (
      <div className="fancy-textbox">
          {props.children}
      </div>
  )
};

export default SectionText;