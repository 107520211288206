import React, {useEffect, useState} from "react";
import SectionText from "../../Reusable/SectionText/SectionText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faWindowClose, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import PNLetterCards from "./graph/PNLetterCards";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";



const sectionTitle = "震災で亡くなった方々への思い"
const SectionTextFiller = () => {
  return (
    <>
      「東日本大震災でお亡くなりになられた方々への思いをお聞かせください」
    </>
  )
}


const PositiveNegativeThoughts = ({ width, setSelectedGraphTitle }) => {

  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [])

  return (
    <section className="section">

      <Link to={`/`}>
          <button 
            className="graph-close" 
            //onClick={() => setSelectedGraphTitle("homepage")}
          >
            <FontAwesomeIcon icon={faWindowClose}/>
          </button>  
      </Link> 

      <h3 className="section-title">{sectionTitle}</h3>

      {/* 
      <SectionText>
        <SectionTextFiller/>
      </SectionText>
      */}

      <Link to={"/居住、滞在先での気持ち"}>
        <FontAwesomeIcon className="back-arrow" icon={faChevronLeft}/>
      </Link>
      <Link to={"/大切な人へ"}>
        <FontAwesomeIcon className="forward-arrow" icon={faChevronRight}/>
      </Link>

      <PNLetterCards width={width}/>

    </section>
  )
};

export default PositiveNegativeThoughts