import React, { useLayoutEffect, useState } from "react";
import Header from "../Components/Sections/Header/Header";
import NumberMoves from "../Components/Sections/NumberMoves/NumberMoves";
import IssuesResidence from "../Components/Sections/IssuesResidence/IssuesResidence";
import ComplaintsOverTime from "../Components/Sections/ComplaintsOverTime/ComplaintsOverTime";
import IssuesResidenceIndividual from "../Components/Sections/IssuesResidenceIndividual/IssuesResidenceIndividual";
import PositiveNegativeThoughts from "../Components/Sections/PositiveNegativeThoughts/PositiveNegativeThoughts";
import PeopleMentions from "../Components/Sections/PeopleMentions/PeopleMentions";
import CurrentResidence from "../Components/Sections/CurrentResidence/CurrentResidence";
import FamilyInfluence from "../Components/Sections/FamilyInfluence/FamilyInfluence";
import MissingPeopleWordCloud from "../Components/Sections/MissingPeopleWordcloud/MissingPeopleWordcloud";
import ComplaintsOverResidence from "../Components/Sections/ComplaintsOverResidence/ComplaintsOverResidence"
import HomePage from "../Components/Sections/HomePage/HomePageRouter"
import { HashRouter as Router, Switch, Route } from 'react-router-dom'; 
import ScrollToTop from "../Components/Reusable/ScrollToTop"

// For responsive graphs
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

// All the titles 
const title1 = "避難所、仮設･･･流転の日々";
const title2 = "移動回数困りごと";
const title3 = "住居環境と困りごと"
const title4 = "移動先での困りごと（個別）";

const title5 = "本設へ、遺族の決断";
const title6 = "本設での家族の暮らしの悩み";

const title7 = "居住、滞在先での気持ち"
const title8 = "震災でお亡くなりになられた方々への思い"
const title9 = "大切な人へ"
const title10 = "あなたをさがして"

const titles = [title1, title2, title3, title4, title5, title6, title7, title8, title9]

const FrontPage = () => {
  return (
    <>
      <Header />
      <HomePage />
      <section id="footer">© 2021 東京大学大学院 渡邉英徳研究室　×　岩手日報社</section>
    </>
  )
}

const CompleteApp = () => {
  // get the width and height of the window 
  const [windowWidth, windowHeight] = useWindowSize();
  // keep track of the selected graph title which corresponds to graph we want to display 
  const [selectedGraphTitle, setSelectedGraphTitle] = useState("homepage");


  return (
    <>
    <Router>
      <ScrollToTop />
      <Switch>

        <Route path="/" exact component={FrontPage}/>


        {/* Complaints over Time - Stream */}
        { windowWidth < 900 ? 
          windowWidth < 600 
            ? <Route path="/移動回数と困りごと" exact 
                render={(props) => (
                  <ComplaintsOverTime {...props} width={340}/>
                )}
              />
            : <Route path="/移動回数と困りごと" exact 
              render={(props) => (
                <ComplaintsOverTime {...props} width={580}/>
              )}
            />
          : <Route path="/移動回数と困りごと" exact 
              render={(props) => (
                <ComplaintsOverTime {...props} width={800}/>
              )}
            />
        }


        {/* Complaints over Residence - Pie Charts */}
          <Route path="/住居環境と困りごと" exact 
            render={(props) => (
              <ComplaintsOverResidence {...props}/>
            )}
          />

   
        {/* Issues Residence Individual - Survey */} 
        {
          windowWidth < 600 
            ? <Route path="/移動先での困りごと（個別）" exact 
                render={(props) => (
                  <IssuesResidenceIndividual {...props} width={250} height={500}/>
                )} 
              />
            : <Route path="/移動先での困りごと（個別）" exact 
                render={(props) => (
                  <IssuesResidenceIndividual {...props} width={500} height={500}/>
                )} 
              />
        }

        
        {/* Current Residence - Bar Chart w/ Boxes */}
        {
          windowWidth <= 700
            ? <Route path="/本設へ、遺族の決断" exact
                render={(props) => (
                  <CurrentResidence {...props} width={600} />
                )}
            />
            : <Route path="/本設へ、遺族の決断" exact
                render={(props) => (
                  <CurrentResidence {...props} width={700} />
                )}
              />
        }


        {/* Family Influence - Bar Chart w/ Cards */}
        {
          windowWidth < 600 
            ? <Route path="/本設での家族の暮らしの悩み" exact
                render={(props) => (
                  <FamilyInfluence {...props} width={310} />
                )}
            />
            : <Route path="/本設での家族の暮らしの悩み" exact
                render={(props) => (
                  <FamilyInfluence {...props} width={460} />
                )}
            />
        }

        
        {/* Number of Moves - NightingaleSemi  */}
        {
          windowWidth < 600 
            ? <Route path="/居住、滞在先での気持ち" exact
                render={(props) => (
                  <NumberMoves {...props} width={300} graph="bar"/>
                )}
            />
            : windowWidth > 900 
            ? <Route path="/居住、滞在先での気持ち" exact
                render={(props) => (
                  <NumberMoves {...props} width={750} graph="radial"/>
                )}
              />
            : <Route path="/居住、滞在先での気持ち" exact
                render={(props) => (
                  <NumberMoves {...props} width={500} graph="radial"/>
                )}
              />
        }


        {/* Positive and Negative Thoughts - Words in Boxes */}
        <Route path="/震災でお亡くなりになられた方々への思い" exact
          render={(props) => (
            <PositiveNegativeThoughts {...props} width={windowWidth}/>
          )}
        />
        

        {/* People Mentions - Bar Chart w/ Boxes */}
        {
          windowWidth < 600 
            ? <Route path="/大切な人へ" exact
                render={(props) => (
                  <PeopleMentions {...props} width={600} animate={false}/>
                )}
              />
            : <Route path="/大切な人へ" exact
                render={(props) => (
                  <PeopleMentions {...props} width={700} animate={true}/>
                )}
              />
        }


        {/* Missing People Word Cloud */}
        {
          windowWidth < 900 
            ? <Route path="/あなたをさがして" exact
                render={(props) => (
                  <MissingPeopleWordCloud {...props} width="80%"/>
                )}
              />
            : <Route path="/あなたをさがして" exact
                render={(props) => (
                  <MissingPeopleWordCloud {...props} width="60%"/>
                )}
              />
        } 

      </Switch>
    </Router>
    </>
  )
};

export default CompleteApp;