import React, { useState, useRef, useEffect } from 'react'
import * as d3 from "d3";
import * as chroma from "chroma-js";
//import { positiveLetters, negativeLetters } from "./data/data.js"; // old version of the data
import {LetterCardThoughts, LetterCardSmallThoughts} from "../../../Reusable/LetterCard/LetterCard";
import "../PositiveNegativeThoughts.css";
import dataLoad from "../../../../data/thoughts_sentiments.csv";
import _ from "lodash";




const SentimentLetter = ({ data }) => {
  return (
    <div>
      {
        data.map(letter => (
          <LetterCardThoughts letter={letter}></LetterCardThoughts>
        ))
      }  
    </div>
  )
}

const SentimentLetterSmall = ({ data }) => {
  return (
    <div>
      {
        data.map(letter => (
          <LetterCardSmallThoughts letter={letter}></LetterCardSmallThoughts>
        ))
      }  
    </div>
  )
}

const Arrow = () => {
    /// refs ///
    const svgRef = useRef();

    /// constants ///
    const svgWidth = 35;
    const svgHeight = 680;
    const arrowColour = "red";
    const colourUp = "#b76935";
    const colourDown = "#143642";
    const arrowSize = 20;
    const strokeWidth = 2;


    /// D3 code /// 
    useEffect(() => {

      /// Drawing area ///
      const svg = d3.select(svgRef.current)
        .attr("width", svgWidth)
        .attr('height', svgHeight)

      /// Defs ///
      const defs = svg.append("defs");

      // Arrow heads 
      const arrowMarker = (arrowSize, id, colour) => {
        defs.append("marker")
          .attr('id', id)
          .attr('viewBox', `0 0 ${arrowSize} ${arrowSize}`)
          .attr('refX', arrowSize)
          .attr('refY', arrowSize / 2)
          .attr('markerUnits', 'userSpaceOnUse')
          .attr('markerWidth', arrowSize)
          .attr('markerHeight', arrowSize)
          .attr('orient', 'auto-start-reverse')
          .append('path')
            .attr('fill', 'none')
            .attr('stroke', colour)
            .attr("stroke-width", strokeWidth)
            .attr('d', `M0,0 L${arrowSize},${arrowSize / 2} L0,${arrowSize}`)
        }
      const arrowUp = arrowMarker(arrowSize, "arrow-up", colourUp);
      const arrowDown = arrowMarker(arrowSize, "arrow-down", colourDown);

      // Gradient (for the line)
      const colorScale = chroma.scale([colourUp, colourDown].map(color => chroma(color).saturate(0)))
      const linearGradient = (id, colors, x1, x2, y1, y2) => {
        defs
          .append('linearGradient')
          .attr('id', id)
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', x1).attr('x2', x2)
          .attr('y1', y1).attr('y2', y2)
          .selectAll('stop')
          .data(colors).join('stop')
          .attr('offset', (d, i) => `${Math.floor(i * 100 / (colors.length - 1))}%`)
          .attr('stop-color', d => d.color)
          .attr('stop-opacity', d => d.opacity > -1 ? d.opacity : 1)
      }
      const linear = linearGradient(
        'gradient', 
        [
          {color: colorScale(0)},
          {color: colorScale(0.5)},
          {color: colorScale(1)},
        ], 0, 0, 0, svgHeight)

      /// Graph /// 
      // Line with the arrow heads
      const group = svg.append("g")
      const line = group.append('line')
        .attr("marker-end", "url(#arrow-down)")
        .attr("marker-start", "url(#arrow-up)")
        .attr("x1", 10)
        .attr("x2", 10)
        .attr("y1", 50)
        .attr("y2", svgHeight - 20)
        .attr("stroke", "url(#gradient)")
        .attr("stroke-width", strokeWidth)

      group.append("text")
        .attr("x", svgWidth/2)
        .attr("y", 50)
        .text("+")
        .style("font-size", "30px")
        .style("font-weigth", "bold")

      group.append("text")
        .attr("x", svgWidth/2)
        .attr("y", svgHeight)
        .text("-")
        .style("font-size", "30px")
        .style("font-weigth", "bold")

    }, [])


  return (
    <>
      <svg ref={svgRef}></svg>
    </>
  )
}


const PNLetterCards = ({ width }) => {

  const [positiveThoughts, setPositiveThoughts] = useState(null);
  const [negativeThoughts, setNegativeThoughts] = useState(null);

  /// Data load ///
  useEffect(() => {
    d3.csv(dataLoad, d3.autoType).then(d => {
      const positive = _.filter(d, person => person['sentiment_google'] > 0)
      const negative = _.filter(d, person => person['sentiment_google'] < 0)
      // remove people who not OK with name 
      const positiveApproved = _.filter(positive, person => person['HP（名前）'] === "OK")
      const negativeApproved = _.filter(negative, person => person['HP（名前）'] === "OK")
      setPositiveThoughts(positiveApproved)
      setNegativeThoughts(negativeApproved)
    })
  }, [])

    return (
      <>
        <div className="thoughts-section">
          <Arrow/>
          {
            width < 770 
            ?  <div className="thoughts-container-overall">
                <div className="thoughts-container positive">
                  {positiveThoughts ? <SentimentLetterSmall data={positiveThoughts}/> : null}
                  
                </div>
                <div className="thoughts-container negative">
                  {negativeThoughts ? <SentimentLetterSmall data={negativeThoughts} /> : null}
                
                </div>
              </div>
            : <div className="thoughts-container-overall">
                <div className="thoughts-container positive">
                  {positiveThoughts ? <SentimentLetter data={positiveThoughts}/> : null}
                  
                </div>
                <div className="thoughts-container negative">
                  {negativeThoughts ? <SentimentLetter data={negativeThoughts} /> : null }
                 
                </div>
              </div>
          }

        </div>   
      </>
    )
  };
  
  export default PNLetterCards;