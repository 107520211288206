import React from "react";
import "./LetterCard.css";
import images from './images'; //Your images folder location


// get the id with ID -> you can match it to avatar name 
// if there is no image for that ID, use the default "user" icon 
// also if the usage of photo is not OK, use the default 'user' icon

const LetterCardThoughts = ({letter}) => {
  return (
    <div className="letter-card">
      <div className="letter-card-name-and-photo">
        <h4　className="letter-card-heading">
          {letter['HP（名前）'] == 'OK' ? letter['お名前'] + "さん"　: "無名"}
        </h4>
        <div className="avatar-photo-container">
          <img className="avatar-photo" src={
              letter['HP（顔写真）'] === 'OK' ? 
              images['id' + letter['ID'].toString()] 
                ? images['id' + letter['ID'].toString()]
                : images['user']
              : images['user']
            } 
            alt="photo" 
            />
        </div>
      </div>

      <hr className="letter-divider"></hr>
      <p>{letter['東日本大震災でお亡くなりになられた方々への思いをお聞かせください']}</p>
    </div>
  )
}

const LetterCardSmallThoughts = ({letter}) => {
  return (
    <div className="letter-card-small-thoughts">
      <div className="letter-card-name-and-photo">
        <h4　className="letter-card-heading">
          {letter['HP（名前）'] == 'OK' ? letter['お名前']　+ "さん" : "無名"}
        </h4>
        <div className="avatar-photo-container">
          <img className="avatar-photo" src={
              letter['HP（顔写真）'] === 'OK' ? 
              images['id' + letter['ID'].toString()] 
                ? images['id' + letter['ID'].toString()]
                : images['user']
              : images['user']
            } 
            alt="photo" 
            />
        </div>
      </div>

      <hr className="letter-divider"></hr>
      <p>{letter['東日本大震災でお亡くなりになられた方々への思いをお聞かせください']}</p>
    </div>
  )
}

const LetterCardSmallFamilyInfluence = ({letter}) => {
  return (
    <div className="letter-card-small-familyinfluence">

      <div className="letter-card-name-and-photo">
        <h4　className="letter-card-heading">
          { letter['name_ok'] == 'OK' ? letter['name']　+ "さん" : "無名"}
        </h4>
        <div className="avatar-photo-container">
          <img className="avatar-photo" src={
              letter['photo_ok'] === 'OK' ? 
              images['id' + letter['id'].toString()] 
                ? images['id' + letter['id'].toString()]
                : images['user']
              : images['user']
            } 
            alt="photo" 
            />
        </div>
      </div>

      <hr className="letter-divider"></hr>
      <p>{letter.letter}</p>
    </div>
  )
}

const LetterCardSmallMissingPeopleWordcloud = ({letter}) => {
  return (
    <div className="letter-card-small-missingpeople-wordlcoud">
      <div className="letter-card-name-and-photo">
        <h4　className="letter-card-heading">
          { letter["HP（名前）"] == "OK" ? letter["お名前"] + "さん" : "無名"}
        </h4>
        <div className="avatar-photo-container">
          <img className="avatar-photo" src={
              letter['HP（顔写真）'] === 'OK' ? 
              images['id' + letter['ID'].toString()] 
                ? images['id' + letter['ID'].toString()]
                : images['user']
              : images['user']
            } 
            alt="photo" 
            />
        </div>
      </div>
      <hr className="letter-divider"></hr>
      <p>{letter['東日本大震災でお亡くなりになられた方々への思いをお聞かせください']}</p>
    </div>
  )
}


export {LetterCardThoughts, LetterCardSmallThoughts, LetterCardSmallFamilyInfluence, LetterCardSmallMissingPeopleWordcloud};