import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import * as _ from "lodash";
import "../NumberMoves.css";
import { data_all, data_missing, data_nomissing, data_all_residence } from "../../../../data/num_moves.js";

const BarChart = ({ width }) => {
  const svgRef = useRef();
  const xAxisRef = useRef();
  const yAxisRef = useRef();
  const legendRef = useRef();
  const graphRef = useRef();

  // VARIABLES //
  //const width = 300;
  const height = width*1.5;
  const margin = {top: 10, right: 0, bottom: 30, left: 43}

  // Color options 
  const colours = ['#C87741', '#815839','#263C41', '#22596D']

  // Data variables used in chart 
  const chronologyColumn = "ResidenceType" // by residence

  // const [data, setData] = useState(data_all) // by number of moves 
  const [data, setData] = useState(data_all_residence) // by residence

  useEffect(() => {

    /// Data transform ///
    const stack = d3.stack()
      .keys(data.columns.slice(1)) // the categories 
    const series = stack(data).map(d => (d.forEach(v => v.key = d.key), d))

    /////////////////////
    //////  Scales //////
    /////////////////////
    // X Scale - band 
    const xScale = d3.scaleBand()
      .domain(data.map(d => d[chronologyColumn]))
      .range([margin.left, width-margin.right])
      .padding(0.1)

    // Y Scale - linear by number of answers for each type
    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.total)])
      .range([height - margin.bottom, margin.top])

    // Colour Scale 
    const colourScale = d3.scaleOrdinal()
      .domain(data.columns.slice(1))
      .range(colours)


    /////////////////////
    /////// Axes ////////
    /////////////////////
    // X Axis 
    const xAxis = g => g
      .attr("transform", `translate(${0}, ${height-margin.bottom})`)
      .attr("class", "bar-x-axis")
      .call(d3.axisBottom(xScale).tickFormat(i => 
        i == 1 ? "避難所" : i == 2 ? "仮住まい" : "本設住宅"
        ).tickSizeOuter(0))

    // Y Axis 
    const yAxis = g => g  
      .attr("transform", `translate(${margin.left}, ${0})`)
      .attr("class", "bar-y-axis")
      .call(d3.axisLeft(yScale).tickFormat(i => `${i}回`).tickSizeOuter(0).ticks(5))
      .call(g => g.select(".domain").remove())

    // call the axes 
    d3.select(xAxisRef.current).call(xAxis)
    d3.select(yAxisRef.current).call(yAxis)
              


    /////////////////////
    ////// Legend ///////
    /////////////////////
    const legend = g => g
      .attr("transform", `translate(${width - 70}, ${100})`)
      .selectAll("g")
      .data(data.columns.slice(1).reverse())
      .join("g")
      .attr("transform", (d, i) => `translate(${-40},${(i - (data.columns.length - 1) / 2) * 20 - 40})`)
      .call(g => g
        .selectAll(".bar-legend-rect")
        .data(d => [d])
        .join("rect")
        .classed("bar-legend-rect", true)
          .attr("width", 20)
          .attr("height", 20)
          .attr("fill", colourScale))
      .call(g => g
        .selectAll(".bar-legend-text")
        .data(d => [d])
        .join("text")
        .classed("bar-legend-text", true)
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", "0.35em")
          .text(d => d))

    d3.select(legendRef.current).call(legend)

    /////////////////////
    ////// Graph ////////
    /////////////////////
    // Graphing area
    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height)

    const t = d3.transition().duration(1000);
        
    // Groups for the bars 
    // make one group for each level of the stacked bar chart 
    const stackLevels = d3.select(graphRef.current)
      .selectAll("g")
      .data(series)
      .join("g")

    // Bars within each level 
    const bars = stackLevels
      .selectAll("rect")
      .data(d => d)
      .join("rect")
        .attr("fill", d => colourScale(d.key)) 
        .attr("x", (d, i) => xScale(d.data.ResidenceType)) // d.data comes from the stacks transformation
        .attr("y", yScale(0)) // y position from the top of each stack 
        .attr("height", 0) // top to bottom 
        .attr("width", xScale.bandwidth())
        .transition(t)
          .attr("y", d => yScale(d[1])) // y position from the top of each stack 
          .attr("height", d => yScale(d[0]) - yScale(d[1])) // top to bottom 




  }, [data, width])


  return (
      <div>
        <svg
            ref={svgRef}
            width={width}
            height={height}
          >
            <g className="graph" ref={graphRef}></g>
            <g className="x-axis" ref={xAxisRef}></g>
            <g className="y-axis" ref={yAxisRef}></g>
            <g className="legend" ref={legendRef}></g>
        </svg>
      </div>
  )
};

export default BarChart;