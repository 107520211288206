// DATA // 
// Number of people who have moved each time 
// Answers to the question of did they remember the disaster while there 
// Split by whether they have a missing person or not 
// IMPORTANT: I have added .1 decimal point to the 0s to make the animations work 

import * as d3 from "d3";

// BY MOVE NUMBER //
// all the people
const data_all = d3.csvParse(`MoveNumber,非常にあった,少しあった,あまりなかった,なかった
  1,172,70,64,99
  2,179,96,61,60
  3,113,99,49,45
  4,65,51,37,26
  5,32,25,9,10
  6,10,14,7,8
  7,5,4,4,3
  8,4,1,0.1,1
  9,2,0.1,2,0.1`, (d, _, columns) => {
  let total = 0;
  for (let i = 1; i < columns.length; ++i) total += d[columns[i]] = +d[columns[i]];
  d.total = total;
  return d;
})

// people without missing people 
const data_nomissing = d3.csvParse(`MoveNumber,非常にあった,少しあった,あまりなかった,なかった
    1,126,52,45,81
    2,134,75,45,47
    3,87,72,41,37
    4,50,43,27,20
    5,29,19,7,7
    6,9,12,7,6
    7,5,3,4,3
    8,4,0.1,0.1,1
    9,2,0.1,1,0.1`, (d, _, columns) => {
  let total = 0;
  for (let i = 1; i < columns.length; ++i) total += d[columns[i]] = +d[columns[i]];
  d.total = total;
  return d;
})

// people with missing people 
const data_missing = d3.csvParse(`MoveNumber,非常にあった,少しあった,あまりなかった,なかった
    1,46,18,20,21
    2,46,21,18,14
    3,27,27,10,9
    4,17,8,11,6
    5,5,6,2,4
    6,2,2,0.1,3
    7,1,1,0.1,1
    8,0.1,1,0.1,0.1
    9,0.1,0.1,1,0.1`, (d, _, columns) => {
  let total = 0;
  for (let i = 1; i < columns.length; ++i) total += d[columns[i]] = +d[columns[i]];
  d.total = total;
  return d;
})

// BY YEAR NUMBER //
const data_all_year_prop = d3.csvParse(`MoveNumber,非常にあった,少しあった,あまりなかった,なかった
2011,0.431157,0.226593,0.167585,0.174666
2012,0.300000,0.266667,0.166667,0.266667
2013,0.266667,0.466667,0.133333,0.133333
2014,0.227273,0.500000,0.181818,0.090909
2015,0.142857,0.285714,0.214286,0.357143
2016,0.300000,0.350000,0.250000,0.100000
2017,0.200000,0.200000,0.100000,0.500000
2018,0.250000,0.250000,0.375000,0.125000
2019,0.243243,0.540541,0.135135,0.081081
2020,0.200000,0.333333,0.066667,0.400000`, (d, _, columns) => {
  let total = 0;
  for (let i = 1; i < columns.length; ++i) total += d[columns[i]] = +d[columns[i]];
  d.total = total;
  return d;
})

const data_all_year_num = d3.csvParse(`MoveNumber,非常にあった,少しあった,あまりなかった,なかった
2011,548.0,288.0,213.0,222.0
2012,9.0,8.0,5.0,8.0
2013,4.0,7.0,2.0,2.0
2014,5.0,11.0,4.0,2.0
2015,2.0,4.0,3.0,5.0
2016,6.0,7.0,5.0,2.0
2017,2.0,2.0,1.0,5.0
2018,4.0,4.0,6.0,2.0
2019,9.0,20.0,5.0,3.0
2020,3.0,5.0,1.0,6.0`, (d, _, columns) => {
  let total = 0;
  for (let i = 1; i < columns.length; ++i) total += d[columns[i]] = +d[columns[i]];
  d.total = total;
  return d;
})

/// BY TYPE OF RESIDENCE /// 
// all the people
const data_all_residence = d3.csvParse(`ResidenceType,非常にあった,少しあった,あまりなかった,なかった
  1,442,189,147,192
  2,185,167,82,58
  3,96,176,117,65`, (d, _, columns) => {
  let total = 0;
  for (let i = 1; i < columns.length; ++i) total += d[columns[i]] = +d[columns[i]];
  d.total = total;
  return d;
})


export {data_all, data_nomissing, data_missing, data_all_year_prop, data_all_year_num, data_all_residence}