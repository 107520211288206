import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { TimelineLite, Back } from "gsap/all";
import Highlighter from "react-highlight-words";
import myData from "../../../../data/all_family_entries_3.json";
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import _ from "lodash";
import "../PeopleMentions.css"
import chroma from "chroma-js";


const BarWithBoxes = ({ width, animate }) => {
  const chartRef = useRef();
  const [data, setData] = useState(
    d3.sort(myData, (a,b) => d3.ascending(a.values.length, b.values.length))
    )
  const [animation, setAnimation] = useState(null);
  // for the text and highlighted words to be displayed 
  const [selectedText, setSelectedText] = useState("クリック・タップで、個別の回答表示");
  const [selectedWords, setSelectedWords] = useState(["placeholder"]);


  useEffect(() => {

    // SCALES //
    //  map years to colours 
    //const colors = [ "#143642", "#263c41", "#38413f", "#4a473e", "#5c4d3c", "#935e38", '#b76935']
    const colors = chroma.scale(['#C87741', '#815839', '#263C41', '#22596D']).mode('rgb').colors(7)

    const scaleColor = d3.scaleOrdinal()
      .domain(data.map(d => d.key)) 
      .range(colors);


    // GRAPH //

    // Within the chart div, create one div with class container 
    // for each element in the nest array - i.e. 4 elements 
    // these are the divs that hold the group data 
    const graph = d3.select(chartRef.current);
    const group = graph
      .selectAll(".bb-container")
      .data(data)
      //.sort((a,b) => d3.ascending(a.key, b.key))
      .join("div")
      .attr("class", "bb-container")

    
    // add text at the bottom of each bar for each category
    const textXAxisContainer = group
        .append("div")
        .attr("class", "bb-x-axis-peoplementions")

    const textXAxis = textXAxisContainer
        .append("div")
        .attr("class", "bb-x-axis-peoplementions-item")
        .text(d => d.key)


    // add text on the top of each bar with number per category
    const textTop = group
      .selectAll(".bb-info-peoplementions")
      .data(d => [d])
      .join("div")
      .attr("class", "bb-info-peoplementions")
        // calculate the position of each div manually as so 
        // length/4 is the number of rows of each bar (as there are 4 squares per row)
        // each square is 12px and has 1 px margin on each side so 12 + 2
        // then if there is a last row it should be added + some padding so 
        // 12 + 2 + padding = 15 
        .style("bottom", d => 
          width == 600 
            ? (d.values.length/3 * (10+2) + 115).toString() + 'px'
            : (d.values.length/4 * (12+2) + 18).toString() + 'px'
        )
        .html(d => d.values.length + "人")
        .style('cursor', 'default')
        .style('opacity', 0.7)
        // add info to the div when it is hovered, e.g. the numbers per sub-category
        .on("mouseenter", function(e, datum){
          d3.select(this)
          .style("z-index", 10)
          .style('opacity', 1)
          .style("padding", '7px')
          .style("font-size", "10px")
          .style("transform", `translate(${0}, ${10}px)`)
          .html(d => 
            `
              ${"行方不明の家族がいない: " + (_.filter(d.values, { 'missing_people': 'いない' })).length}
              <br/>
              ${"行方不明の家族がいる: " + (_.filter(d.values, { 'missing_people': 'いる' })).length}
            `)
        })
        .on("mouseleave", function(e, datum){
          d3.select(this)
            .style("z-index", 0)
            .style('opacity', 0.7)
            .style("padding", '2px')  
            .style("font-size", "12px")
            .style("transform", `translate(${0}, ${0}px)`)
            .html(d => d.values.length + "人")
        })


    // the little div boxes inside the 4 big boxes
    group
      .selectAll(".box")
      .data(d => d.values.sort((a, b) => d3.ascending(a.missing_people, b.missing_people))) 
      .join("div")
      .attr("class", "box")
      .style("background-color", d => scaleColor(d.key)) 
      .style("opacity", d => d.missing_people == "いない" ? 1 : 1)
      .on("click", displayResponses)
      //.on("mouseover", displayResponses)
      //.on("mouseout", hideResponses)

      function displayResponses(e, datum) {
        const text = datum.text;
        const words = datum.words;
        if (text) {
          setSelectedText(text)
          setSelectedWords(words)
        } else {
          setSelectedText("説明なし")
          setSelectedWords([""])
        }
      }

      function hideResponses(e, datum) {
        setSelectedWords(["placeholder"])
        setSelectedText("クリック・タップで、個別の回答表示")
      }


    // LEGEND //
    /*
    const legendContainer = graph
      .append("div")
      .attr("class", "legend-container")

    const legendBoxes = legendContainer
      .selectAll(".legend")
      .data(
        data.map(d => d.key).reverse()
      )
      .join("div")
      .attr("class", "legend")
      .style("background-color", d => scaleColor(d))

    legendBoxes
      .selectAll(".legend-text")
      .data(d => [d])
      .join("text")
      .attr("class", "legend-text")
      .text(d => d)
      //.text("family")
    */



    // ANIMATION //
    if (animate) {
      gsap.registerPlugin(ScrollTrigger);
      //intitiate paused animation
      let anim = new TimelineLite({ 
        scrollTrigger: {
          trigger: ".bar-with-boxes-root",
          start: "top bottom",
          end: "top center",
        },
        paused: false 
      });
      anim.staggerTo(".box", 1, {
        scale: 1,
        ease: Back.easeOut,
        stagger: {
          grid: "auto",
          from: "start",
          axis: "y",
          each: 0.01
        }
      });
    }


  }, [width, data, animate])

  // Button events for triggering the animation
  const playAnimation = (e) => {
    e.preventDefault();
    if (!animation.isActive()) {
      animation.play(0);
    }
  }
  const reverseAnimation = (e) => {
    e.preventDefault();
    animation.reverse();
  }


  return (
    <>
      <div className="bar-with-boxes-root">
        <div ref={chartRef} className="bb-chart"></div>
      </div>
      <br/>
      <br/>
      
        {
          selectedText ?
            <div className="selected-text-people">
              <div className="message">
                <Highlighter
                    highlightStyle={{
                      "backgroundColor": '#C8774166',
                      "opacity" : 1,
                      "marginLeft": "3px",
                      "marginRight": "3px",
                      "paddingLeft": "3px",
                      "paddingRight": "3px",
                      "borderRadius": "5px"
                    }}
                    searchWords={selectedWords}
                    autoEscape={true}
                    textToHighlight={selectedText}
                />
              </div>
            </div>
          : null
        }
      
    </>
  )
};

export default BarWithBoxes;