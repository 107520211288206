import React from "react";
import "./App.css";
import CompleteApp from "./CompleteApp/CompleteAppRouter";

const App = () => {
  return (
    <CompleteApp />
  )
};

export default App;