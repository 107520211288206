import React, { useEffect, useState } from 'react';
import BarChart from "./BarChartD3";
import {LetterCardSmallFamilyInfluence} from "../../../Reusable/LetterCard/LetterCard"
import lettersAll from "../../../../data/familyInfluence.json"
import "../FamilyInfluence.css"
import _ from "lodash";



const TopicBoxesLetters = ({width}) => {

  const [topic, setTopic] = useState("no topic")

  const displayTopicLetters = (e) => {
    const currentTopic = e.id;
    setTopic(currentTopic)
  }

  // filter out the data for people who are not ok with name displayed 
  const data = _.filter(lettersAll, letter => letter.name_ok == "OK")

  return (
      <div className="container-family-section">
        <div className="topic-boxes">
          <BarChart 
            width={width}
            onClick={displayTopicLetters} 
          />
        </div>
        {topic !== "no topic" ?
          <div className="letter-cards" >
            {data.filter(el => el.topic == topic).map((letter, i) => (
              <LetterCardSmallFamilyInfluence
                key={i}
                letter={letter}
              />
            ))}
          </div>
          :null
        }
      </div>
  )
};

export default TopicBoxesLetters;