import React, { useRef, useState, useEffect } from 'react';
import { TagCloud } from 'react-tagcloud'

const colorLight = '#353535'
const colorDark = '#121217'
const colorPositive = "#175676"
const colorNegative = "#b76935"


const WordCloud = ({ width, setSelectedLemma, topWordsOnlyPositive, topWordsOnlyNegative, data }) => {
  const customRenderer = (tag, size) => (
    <span
      key={tag.value}
      style={{
        animation: 'blinker 3s linear infinite',
        animationDelay: `${Math.random() * 2}s`,
        fontSize: `${size}em`,
        margin: '7px',
        padding: '0px',
        display: 'inline-block',
        /*
        color: topWordsPositive.includes(tag.value) 
          ? colorPositive 
          : topWordsNegative.includes(tag.value) 
          ? colorNegative
          : colorLight,
        */
        //color: topWordsMissing.includes(tag.value) ? colorPositive : colorLight,
        color: topWordsOnlyPositive.includes(tag.value) 
          ? colorPositive 
          : topWordsOnlyNegative.includes(tag.value) 
          ? colorNegative
          : colorLight,
        opacity: '1'
      }}
    >
      {tag.value}
    </span>
  )
  const minSize = 1.3
  const maxSize = 3.3
  //const width = "60%"

  return (
    <TagCloud
      minSize={minSize}
      maxSize={maxSize}
      renderer={customRenderer}
      tags={data}
      style={{ 
        borderRadius: '10%', 
        fontWeight: 'bold', 
        width: width,
        cursor: 'default',
        borderRadius: '10%',
        margin: 'auto',
        fontFamily: 'Noto Serif JP, serif'
      }}
      onClick={tag => setSelectedLemma(tag.value)}
    />
  )
};




export default WordCloud;