import React, {useEffect, useState} from "react";
import SectionText from "../../Reusable/SectionText/SectionText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faWindowClose, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import StreamGraph from "./graph/StreamGraph"
import { Link } from "react-router-dom";
import ComplaintsOverResidence from "../ComplaintsOverResidence/ComplaintsOverResidence"



const sectionTitle = "移動回数と困りごと"
const SectionTextFiller = () => {
  return (
    <>
      避難所・仮設・本設･･･悩みも変化   
    </>
  )
}

const GraphExplanation = ({closeExplanation}) => {
  return (
    <>
      <div className="normal-textbox">
        <FontAwesomeIcon icon={faWindowClose} 
          className="explanation-close" 
          onClick={closeExplanation}
         />
          このグラフは、回答者が各移動場所で抱えた困りごとの変化を表しています。面積が広い事柄ほど、多くの人が困ったということを意味します。 
      </div>
    </>
  )
}



const ComplaintsOverTime = ({ width, setSelectedGraphTitle }) => {

  const [revealGraphExplanation, setRevealGraphExplanation] = useState(false);
  
  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [])

  const toggleGraphExplanation = () => {
    setRevealGraphExplanation(!revealGraphExplanation)
  }

  return (
    <section className="section">

      <Link to={`/`}>
          <button 
            className="graph-close" 
            //onClick={() => setSelectedGraphTitle("homepage")}
          >
            <FontAwesomeIcon icon={faWindowClose}/>
          </button>  
      </Link> 

      <h3 className="section-title">{sectionTitle}</h3>

      {/*      
      <SectionText>
        <SectionTextFiller/>
      </SectionText>
       */}

      <Link to={"/住居環境と困りごと"}>
        <FontAwesomeIcon className="forward-arrow" icon={faChevronRight}/>
      </Link>

      <button 
          className="graph-explain-icon" 
          //onMouseEnter={() => isMobile ? console.log("mobile") : setRevealGraphExplanation(true)}
          //onMouseLeave={() => isMobile ? console.log("mobile") : setRevealGraphExplanation(false)}
          onClick={() => setRevealGraphExplanation(true)}
        >
          <FontAwesomeIcon icon={faQuestion} />
      </button> 
      { revealGraphExplanation 
        ? <GraphExplanation closeExplanation={() => setRevealGraphExplanation(false)}/> 
        : null 
      }  


      <StreamGraph width={width}/>

    </section>
    
  )
};

export default ComplaintsOverTime