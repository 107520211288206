import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import _ from "lodash";
import "../ComplaintsOverTime.css";
import complaintsByMoveData from "../../../../data/complaintsByMove4.json"; // this corresponds to proportion
import complaintsByResidenceType from "../../../../data/complaintsByResidenceType.csv";  
import chroma from "chroma-js";
import PieCharts from "../../ComplaintsOverResidence/graph/PieCharts"

// make sure these are in the same order as in the data where they are in ascending order 


const coloursAll = [ 
  "#143642", "#263c41", "#38413f", "#4a473e", "#5c4d3c",
  "#6f523b", "#815839", "#935e38", "#a56336",'#b76935',
  "#d18e61", "#e3bba0", "#f1ddd0", "#faf4ef",
]

//const colours = [ "#143642", "#38413f", "#4a473e", "#815839","#a56336",'#b76935']
// chroma colours 
const colours = chroma.scale(['#B76935', '#815839', '#263C41', '#22596D']).mode('rgb').colors(6)
const cremeWhite = "#f6f2e7"
const lightGrey = '#565050'


///////////////////////////////////////////////
////////  PIE CHART W/ RESIDENCE TYPE /////////
///////////////////////////////////////////////
/*
const PieChart = ({ issue, issues }) => {
  /// refs ///
  const svgRef = useRef();
  const gPieRef = useRef();

  /// states ///
  const [data, setData] = useState(null)

  /// dimensions ///
  const width = 320;
  const height = width;
  const innerRadius = width/5;
  const outerRadius = width/3.2;
  const padAngle = 0.15;
  const cornerRadius = 3;

  /// Data load ///
  useEffect(() => {
    d3.csv(complaintsByResidenceType, d3.autoType).then(d => {
      const dataForIssue = _.filter(d, issueEntry => issueEntry["issue_classification"] == issue)

      /// Transform the data for pie ///
      const dataPieTransform  = {
        避難所: dataForIssue[0]["1"],
        仮住まい: dataForIssue[0]["2"],
        本設住宅: dataForIssue[0]["3"]
      }
      let dataPie = []
      for (const [residence, count] of Object.entries(dataPieTransform)) {
        dataPie.push({
          name: residence,
          value: count
        })
      };

      setData(dataPie)
    })
  }, [issue, issues])

  /// D3 code ///
  useEffect(() => {
    if (data) {

      const svg = d3.select(svgRef.current)

      /// colour scale ///
      const colorScale = d3.scaleOrdinal()
        .domain(issues)
        .range(colours)


      /// Patterns ///
      const defs = svg.selectAll("defs").data([0]).join("defs")
      const patternDots = defs
        .append('pattern')
          .attr('id', 'pattern-dots')
          .attr("width", 2)
          .attr("height", 2)
          .attr('patternUnits',"userSpaceOnUse")
          .append("circle")
            .attr("r", 1)
            .attr("fill", "white")
            .attr("fill-opacity", 0.3)

      const patternHorizontal = defs
        .append("pattern")
          .attr("id", "pattern-horizontal")
          .attr("height", 2)
          .attr("width", 2)
          .attr('patternUnits',"userSpaceOnUse")
        .append("rect")
          .attr("height", 1)
          .attr("width", 5)
          .attr("fill", "white")
          .attr("fill-opacity", 0.2)

      const patternVertical = defs
        .append("pattern")
            .attr("id", "pattern-vertical")
            .attr("height", 3)
            .attr("width", 3)
            .attr('patternUnits',"userSpaceOnUse")
        .append("rect")
            .attr("height", 5)
            .attr("width", 1)
            .attr("fill", "white")
            .attr("fill-opacity", 0.2)


      //////////////////
      /// Pie Chart ////
      //////////////////
      const gPie = d3.select(gPieRef.current)
      .attr("transform", `translate(${width/2}, ${height/2})`)

      /// 1. Pie ///
      const pie = d3.pie()
        .sort(null)
        .value(d => d.value)
      const arcs = pie(data)

      /// 2. Arc ///
      const arc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .padAngle(padAngle)
        .cornerRadius(cornerRadius)

      /// 3. Pie chart ///
      const pieChart = gPie
        .selectAll(".pie-path") 
        .data(arcs)
        .join("path")
        .classed("pie-path", true)
          .attr("stroke", d => colorScale(issue)) 
          .attr("stroke-opacity", 1)
          .attr("stroke-width", 5)
          .attr("fill", d => colorScale(issue)) 
          .attr("fill-opacity", 1)
          .attr("d", arc)

      const pieChartPattern = gPie
        .selectAll(".pie-path-pattern") 
        .data(arcs)
        .join("path")
        .classed("pie-path-pattern", true)
          .attr('fill', d => 
            d.data.name == "避難所" 
            ? 'url(#pattern-dots)' 
            : d.data.name == "仮住まい" 
            ? 'url(#pattern-horizontal)' 
            : "url(#pattern-vertical)"
          )
          .attr("fill-opacity", 1)
          .attr("d", arc)

      const pieChartMiddle= svg
        .selectAll(".pie-path-middle-text") 
        .data(["住居種類別の数"])
        .join("text")
        .classed("pie-path-middle-text", true)
        .attr("transform", `translate(${width/2}, ${height/2})`)
          .style("fill", lightGrey)
          .attr("text-anchor", "middle")
          .text(d => d)
          .attr("dy", "0.35em")

      /// . Pie chart Labels ///
      const radius = Math.min(width, height) / 2 
      const arcLabel = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius*2)
      // Text on the pie chart 
      const pieChartText = gPie
          .selectAll(".pie-text")
          .data(arcs)
          .join("text")
          .classed("pie-text", true)
            .attr("font-family", "sans-serif")
            .attr("text-anchor", "middle")
            .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
            .call(text => text
              .selectAll(".tspan-1").data(d => [d]).join("tspan").classed("tspan-1", true)
                  .attr("dy", "0.35em")
                  .attr("fill-opacity", 0.8)
                  .attr("fill", lightGrey)
                  .text(d => `${d.data.name}`))
            .call(text => text
              .selectAll(".tspan-2").data(d => [d]).join("tspan").classed("tspan-2", true)
                .attr("dy", "1em")
                .attr("dx", "-3em")
                .attr("text-anchor", "middle")
                .attr("fill-opacity", 0.6)
                .attr("fill", lightGrey)
                .attr("font-size", "1em")
                .text(d => `${d.data.value}回`))


    }
  }, [data])

  return (
    <>
      <svg ref={svgRef} width={width} height={height}>
        <g ref={gPieRef}></g>
      </svg>
    </>
  )
}
*/


///////////////////////////////////////////////
/////  STREAM GRAPH W/ COMPLAINTS PROPS ///////
///////////////////////////////////////////////
const StreamGraph = ({ width }) => {

  /// refs ///
  const svgRef = useRef();
  const gRef = useRef();
  const xAxisRef = useRef();
  const yAxisRef = useRef();
  const legendRef = useRef();
  const xAxisLegendRef = useRef();

  /// states ///
  const [dataStream, setDataStream] = useState(null);
  const [issue, setIssue] = useState(null);
  const [issues, setIssues] = useState(null)

  /// Dimensions ///
  //const width = 800;
  let height
  width == 340 ? height = width*1.1 : height = width/1.8
  let margin = {right: 20, bottom: 30, left: 25}
  width == 340 ? margin.top = 110 : margin.top = 50
  const legendWidth = width/1.2;
  const legendStartPosition = width/10;
  const legendRectHeight = 25;


  //const grouping = cols;

  /// Offset options ///
  const optionsOffset = [
    {name: "expand", value: d3.stackOffsetExpand},
    {name: "none", value: d3.stackOffsetNone},
    {name: "silhouette", value: d3.stackOffsetSilhouette},
    {name: "wiggle", value: d3.stackOffsetWiggle},
    {name: "diverging", value: d3.stackOffsetDiverging}
  ];
  const optionsStackOrder = [
    {name: "appearance", value: d3.stackOrderAppearance},
    {name: "ascending", value: d3.stackOrderAscending},
    {name: "descending", value: d3.stackOrderDescending},
    {name: "insideOut", value: d3.stackOrderInsideOut},
    {name: "none", value: d3.stackOrderNone},
    {name: "reverse", value: d3.stackOrderReverse}
  ]

  /// Data load ///
  useEffect(() => {
    setDataStream(complaintsByMoveData)
  }, [])

  // manually set the options - delete when you settle on an option
  const optionOffset = "none"
  const optionStackOrder = "none"

  /// D3 Code ///
  useEffect(() => {
    if (dataStream){
      // set the keys i.e. the types of issues
      const grouping = _.filter(Object.keys(dataStream[0]), el => el != "number")
      setIssues(grouping)
      /// Data transform ///
      const stack = d3.stack()
        .keys(grouping)
        .offset(_.find(optionsOffset, { 'name': optionOffset}).value) // selected offset 
        .order(_.find(optionsStackOrder, { 'name': optionStackOrder}).value)   

      // remove the entries for the last 1-2 moves 
      const dataStreamLimited = _.filter(dataStream, el => el.number <= 7)
      const series = stack(dataStreamLimited)


      /// Scales ///
      // X Scale - time scale 
      const x = d3.scaleLinear()
        .domain(d3.extent(dataStreamLimited, d => d.number)) // all the dates 
        .range([margin.left, width - margin.right])
      // Y Scale - vertical start and end position for each area 
      const y = d3.scaleLinear()
        .domain([
          d3.min(series, element => d3.min(element, d => d[0])), // the smallest of the lower coord values,
          d3.max(series, element => d3.max(element, d => d[1])) // the largest of the upper coord values 
        ])
        .range([height - margin.bottom, margin.top])
      // Colour scale - discrete with as many numbers as categories 
      const colorScale = d3.scaleOrdinal()
        .domain(grouping)
        .range(colours)
      // X Scale for the legend 
      const xScaleLegend = d3.scaleBand()
        .domain(_.range(grouping.length)) //number of categories = 8
        .range([0, legendWidth])
        .padding(0.1)

      /// Axes ///
      // X Axis 
      const xAxis = g => g 
        .attr("transform", `translate(${0}, ${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(5).tickSizeOuter(0).tickFormat(i => "転居 ".concat((i+1).toString())))
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll("text")
          .attr("class", "stream-text")
          .style("fill", lightGrey)
        )
        .call(g => g.selectAll(".tick")
          .style("color", lightGrey)
        )
      // Y Axis 
      const yAxis = g => g  
        .attr("transform", `translate(${margin.left}, ${0}`)
        .call(d3.axisLeft(y).ticks(1))
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll('text').attr("fill-opacity", 0))
        .call(g => g.select(".tick:last-of-type text").clone()
          .attr("y", 15)
          .attr("x", 10)
          .attr("text-anchor", "start")
          .attr("fill-opacity", 1)
          .attr("opacity", 1)
          //.attr("transform", `rotate(${-90})`)
          .text("遺族が抱えた困りごとの割合")
          .attr("class", "stream-text")
          .style("fill", lightGrey)
          .style("writing-mode", "tb")
        )

      /// Graph ///
      // Chart area
      const svg = d3.select(gRef.current)
        
      // Area
      const area = d3.area()
        .x(d => x(d.data.number))
        .y0(d => y(d[0]))
        .y1(d => y(d[1]))
        .curve(d3.curveNatural)

      // Graph 
      // one path element for each element in the series 
      // i.e. each area that will get stacked 
      // and its d attribute is area 
      const graph = svg.selectAll("path")
        .data(series)
        .join("path")
        .attr("fill", ({key}) => colorScale(key))
        .attr("d", area)
        //.on("click", function(e, datum){ console.log("stream graph", datum) })

        
      /// Legend ///
      let legendTranslateY = 25
      //width == 340 ? legendTranslateY = 5 : legendTranslateY = 25
      const legend = d3.select(legendRef.current)
        .attr("transform", `translate(${legendStartPosition}, ${legendTranslateY})`)

      // title and instructions for the legend 
      const legendTitle = legend
        .selectAll(".legend-title-stream")
        .data(['クリック・タップで表示切り替え'])
        .style("font-size", "0.8em")
        .style("fill", lightGrey)
        .join("text")
        .classed("legend-title-stream", true)
        .text(d => d)
        .attr("dy", "0.35em")
        .attr("transform", `translate(${10}, ${-15})`)


      const xAxisLegend = g => g  
        .attr("transform", `translate(${legendStartPosition}, ${legendRectHeight + legendTranslateY})`)
        .attr("class", "stream-text x-axis-legend")
        .call(d3.axisBottom(xScaleLegend).tickFormat(i => grouping[i]).tickSizeOuter(0))
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll("text")
          .attr("class", "stream-text")
          .style("fill", lightGrey)
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", "rotate(-35)")
        )
        .call(g => g.selectAll(".tick")
          .style("color", lightGrey)
        )

      d3.select(xAxisLegendRef.current).call(xAxisLegend);

      const legendRects = legend 
        .selectAll(".legend-rect")
        .data(grouping)
        .join("rect")
        .classed("legend-rect", true)
        .attr("x", (d, i) => xScaleLegend(i))
        .attr("y", 0)
        .attr("width", xScaleLegend.bandwidth())
        .attr("height", legendRectHeight)
        .attr("fill", d => colorScale(d))
        // on hover highlight the relevant part of the graph for that issue
        .on('mouseover', function(event, datum){
          legendRects.attr("opacity", d => d === datum ? 1 : 0.1)
          graph.attr("opacity", d => d.key === datum ? 1 : 0.1)
          // select the legend by class, use 'call' to get to the text elements 
          // and find the correct tick number based on the index of the datum 
          // in the data array 
          d3.select(".x-axis-legend")
            .call(g => g.selectAll("text")
              .attr("opacity", d => d === _.indexOf(grouping, datum) ? 1 : 0.1)
            )  
        })
        .on('mouseout', function(event, datum){
          legendRects.attr("opacity", 1)
          graph.attr("opacity", 1)
          d3.select(".x-axis-legend")
          .call(g => g.selectAll("text")
            .attr("opacity", 1)
          ) 
        })
        // on click, pass the issue as state for the other graph
        .on('click', function(event, datum){
          setIssue(datum)
        })     
     
      // Call the axes 
      d3.select(xAxisRef.current).call(xAxis);
      d3.select(yAxisRef.current).call(yAxis);

    } 
  }, [dataStream, width])



  return (
    <>
      {/* 
        {
          (issue && issues) 
          ? issue != "その他"
          ?<PieChart issue={issue} issues={issues}/> : null
          : null
        }
      */}
        <div>
          <svg ref={svgRef} width={width} height={height}>
              <g ref={gRef}></g>
              <g ref={xAxisRef}></g>
              <g ref={yAxisRef}></g>
              <g ref={legendRef}></g>
              <g ref={xAxisLegendRef}></g>
          </svg>
        </div>

    </>
  )
}


export default StreamGraph;