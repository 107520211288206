import React, {useEffect, useState} from "react";
import SectionText from "../../Reusable/SectionText/SectionText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faWindowClose, faArrowRight, faUser, faArrowLeft, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Survey from "./graph/Survey"
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";




const sectionTitle = "移動先での困りごと（個別）"
const SectionTextFiller = () => {
  return (
    <>
      滞在場所での悩みは人それぞれ
    </>
  )
}

const GraphExplanation = ({closeExplanation}) => {
  return (
    <>
      <div className="normal-textbox">
        <FontAwesomeIcon icon={faWindowClose} 
          className="explanation-close" 
          onClick={closeExplanation}
         />
        <ul>
          <li>
            回答者一人一人が各移動先で困った事柄が表示されます。回答した選択肢のチェックボックスは色が付きます。
          </li>
          <li>
            画面右にある「避難所」「仮住まい」などの移動先ボタンをクリックすると、その場所での悩みの状況が表示されます。ボタンの数字は、移動が何カ所目かを示しています。
          </li>
          <li>
            <FontAwesomeIcon icon={faArrowLeft}/>  
            <FontAwesomeIcon icon={faUser}/>  
              と 
            <FontAwesomeIcon icon={faUser}/>  
            <FontAwesomeIcon icon={faArrowRight}/>   
            をクリックすると、他の回答が見られます。
          </li>
        </ul>
      </div>
    </>
  )
}



const IssuesResidenceIndividual = ({ width, height, setSelectedGraphTitle }) => {

  const [revealGraphExplanation, setRevealGraphExplanation] = useState(false);

  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [])

  const toggleGraphExplanation = () => {
    setRevealGraphExplanation(!revealGraphExplanation)
  }

  return (
    <section className="section">
            
      <Link to={`/`}>
          <button 
            className="graph-close" 
            //onClick={() => setSelectedGraphTitle("homepage")}
          >
            <FontAwesomeIcon icon={faWindowClose}/>
          </button>  
      </Link> 

      <h3 className="section-title">{sectionTitle}</h3>

      <SectionText>
        <SectionTextFiller/>
      </SectionText>

      <Link to={"/住居環境と困りごと"}>
        <FontAwesomeIcon className="back-arrow" icon={faChevronLeft}/>
      </Link>
      <Link to={"/本設へ、遺族の決断"}>
        <FontAwesomeIcon className="forward-arrow" icon={faChevronRight}/>
      </Link>

      <button 
          className="graph-explain-icon" 
          //onMouseEnter={() => isMobile ? console.log("mobile") : setRevealGraphExplanation(true)}
          //onMouseLeave={() => isMobile ? console.log("mobile") : setRevealGraphExplanation(false)}
          onClick={() => setRevealGraphExplanation(true)}
        >
          <FontAwesomeIcon icon={faQuestion} />
      </button> 
      { revealGraphExplanation 
        ? <GraphExplanation closeExplanation={() => setRevealGraphExplanation(false)}/> 
        : null 
      }   

      <Survey width={width} height={height}/>

    </section>
  )
};

export default IssuesResidenceIndividual