// Question 0: Why did they choose their current residence
// groupby location 
// main question: 'なぜここを本設の住居に選びましたか'
// also include whether family member is mentioned or not 


import React, {useRef, useEffect, useState, createElement} from "react";
import * as d3 from "d3";
import { TimelineLite, Back } from "gsap/all";
import myData from "../../../../data/current_residence_choose_reason.json";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import _ from "lodash";

import "../CurrentResidence.css";


const BarWithBoxes0 = ({ width }) => {
  const chartRef = useRef();
  const [data, setData] = useState(
    // filter out the missing data (missing prefecture) and sort values 
    d3.sort(_.filter(myData, function(entry) { return entry.key !== "missing"}), 
    (a,b) => d3.ascending(a.values.length, b.values.length))
    )
  const [animation, setAnimation] = useState(null);
  // for the text and highlighted words to be displayed 
  const [selectedText, setSelectedText] = useState({value: "をクリック・タップで、個別の回答表示", key: "legend"});
  // SCALES //
  const colors = [ "#22596D",'#B76935'];
  //const colors = ["#175676", "#640d14"];

  const legends = [];
  for (let i = 0; i < colors.length; i++){
    legends[i] = createElement('span', {className: "legend", style: {backgroundColor: colors[i]}});
  }

  /// d3 code ///
  useEffect(() => {

    if (data) {

    const answerOptions = ["family mention", "no family mention"]
    const scaleColor = d3.scaleOrdinal()
      .domain(answerOptions) 
      .range(colors);

    /// GRAPH ///
    // Within the chart div, create one div with class container 
    // for each element in the nest array - i.e. 4 elements 
    // these are the divs that hold the group data 
    const graph = d3.select(chartRef.current);
    const group = graph
      .selectAll(".bb-container-residence")
      .data(data)
      .join("div")
      .attr("class", "bb-container-residence")

    // add text at the bottom of each bar for each category
    const textXAxisContainer = group
      .append("div")
        .attr("class", "bb-x-axis-residence")

    const textXAxis = textXAxisContainer
        .append("div")
        .attr("class", "bb-x-axis-residence-item")
        .text(d => d.key)

    // add text on the top of each bar with number per category
    const textTop = group
      .selectAll(".bb-info-residence-0")
      .data(d => [d])
      .join("div")
        .attr("class", "bb-info-residence-0")
        .style("bottom", d => 
          width == 600
            ? (d.values.length/3 * (10+2) + 95).toString() + 'px' // small screen
            : (d.values.length/4 * (12+2) + 15).toString() + 'px' // large screen
        )
        .html(d => d.values.length + "人")
        .style('cursor', 'default')
        .style('opacity', 0.7)
        // add info to the div when it is hovered, e.g. the numbers per sub-category
        .on("mouseenter", function(e, datum){
          d3.select(this)
          .style("z-index", 10)
          .style('opacity', 1)
          .style("padding", '7px')
          .style("font-size", "10px")
          .style("transform", `translate(${0}, ${10}px)`)
          .html(d => 
            `
              ${"行方不明の家族がいる: " + (_.filter(d.values, { 'answer': 'family mention' })).length}
              <br/>
              ${"行方不明の家族がいない: " + (_.filter(d.values, { 'answer': 'no family mention' })).length}
           `)
        })
        .on("mouseleave", function(e, datum){
          d3.select(this)
            .style("z-index", 0)
            .style('opacity', 0.7)
            .style("padding", '2px')  
            .style("font-size", "12px")
            .style("transform", `translate(${0}, ${0}px)`)
            .html(d => d.values.length + "人")
        })


    // the little div boxes inside the 4 big boxes
    group
      .selectAll(".box-residence")
      .data(d => d.values.sort((a, b) => d3.descending(a.answer, b.answer)))
      .join("div")
      .attr("class", "box-residence")
      .style("background-color", d => scaleColor(d.answer)) 
      .on("click", displayResponses)
      //.on("mouseover", displayResponses)
      //.on("mouseout", hideResponses)

      function displayResponses(e, datum) {
        const explanation = datum.explanation
        if (explanation) {
          setSelectedText({value: explanation, key: "selected"})
        } else {
          setSelectedText({value: "説明無し", key: "selected"})
        }
        
      }

      function hideResponses(e, datum) {
        setSelectedText({value: "をクリック・タップで、個別の回答表示", key: "legend"})
      }


    // LEGEND //
    const legendContainer = graph
      .append("div")
      .attr("class", "legend-container-residence")

    const legendBoxes = legendContainer
      .selectAll(".legend-residence")
      .data(answerOptions)
      .join("div")
      .attr("class", "legend-residence")
      .style("background-color", d => scaleColor(d))

    legendBoxes
      .selectAll(".legend-text-residence")
      .data(d => [d])
      .join("text")
      .attr("class", "legend-text-residence")
      .text(d => d == "family mention" ? "行方不明の家族がいる" : "行方不明の家族がいない")


    // ANIMATION //
    gsap.registerPlugin(ScrollTrigger);
    //intitiate paused animation
    let anim0 = new TimelineLite({ 
      scrollTrigger: {
        trigger: "#residence0",
        start: "top bottom",
        end: "top center",
      },
      paused: false 
    });
    anim0.staggerTo(".box-residence", 1, {
      scale: 1,
      ease: Back.easeOut,
      stagger: {
        grid: "auto",
        from: "start",
        axis: "y",
        each: 0.01
      }
    });
  }

  }, [width, data])

  return (
      <>
        <div className="bar-with-boxes-root-residence" id="residence0">
          <div ref={chartRef} className="bb-chart-residence"></div>
        </div>
        <br/>
        <br/>
        {
          selectedText
              ? (
                  selectedText.key === "legend" ?
                      <div className="selected-text-residence">
                        <div className="message">
                        {legends}
                        {selectedText.value}
                        </div>
                      </div>
                      : <div className="selected-text-residence">
                        <div className="message">
                          {selectedText.value}
                        </div>
                      </div>
              )
              : null
        }

      </>
  )
};

export default BarWithBoxes0;