import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import "../IssuesResidenceIndividual.css"
import _ from "lodash";
import myData from "../../../../data/living_conditions_all_10.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRandom, faUser, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const surveyTitle = "その移動先で困っていたことはありますか？"


// Sort the data - in case we display it in order of people with the most complaints
const sortedData = myData.sort(function(a, b) {
  const lengthA = d3.max(a.data, d => _.filter(d.issues, issue => issue.selected == "yes").length)
  const lengthB = d3.max(b.data, d => _.filter(d.issues, issue => issue.selected == "yes").length)
  return lengthB - lengthA;
});

const lightGrey = "#565050";

//////////////////////////////
///////// Legend ////////////
/////////////////////////////
const Legend = () => {

  /// refs ///
  const svgRef = useRef();
  const gRef = useRef();
  const axisRef = useRef();

  /// constants ///
  const width = 200;
  const height = 100;
  const margin = {top: 10, right: 0, bottom: 0, left: -20}

  const typeOfResidence = ['避難所', '仮住まい', '本設住宅']
  const colourShelter = "#b76935"
  const colourTempResidence = "#22596D"
  const colourMainResidence = "#7c7766"
  const colours = {
    '避難所' : colourShelter,
    '仮住まい' : colourTempResidence,
    '本設住宅' : colourMainResidence,
  }

  /// D3 Code ///
  useEffect(() => {

    const g = d3.select(gRef.current)
      .attr("transform", `translate(${margin.left}, ${margin.top})`)

    /// Scale ///
    const legendScale = d3.scaleBand()
      .domain(typeOfResidence)
      .range([0, width])

    const rectsLegend = g
      .selectAll(".rect-legend")
      .data(typeOfResidence)
      .join("rect")
        .classed("rect-legend", true)
        .attr("width", 30)
        .attr("height", 30)
        .attr("rx", 10)
        .attr("ry", 10)
        .attr("x", d => legendScale(d) + legendScale.bandwidth()/2)
        .attr("y", 0)
        .attr('fill', d => colours[d])

    const rectsText = g => g  
      .call(d3.axisBottom(legendScale))
      .attr("transform", `translate(${-5}, ${40})`)
      .call(g => g.select(".domain").remove())

    d3.select(axisRef.current).call(rectsText)


  }, [])


  return (
    <>
      <div>
        <svg width={width} height={height} ref={svgRef}>
          <g ref={gRef}></g>
          <g ref={axisRef}></g>
        </svg>
      </div>    
    </>
  )
}


const Survey = ({ width, height }) => {

  const svgRef = useRef();
  const gRef = useRef();
  const timelineGRef = useRef();

  // States //
  const [dataIndex, setDataIndex] = useState(0);
  //const [data, setData] = useState(sortedData[dataIndex])
  const [reason, setReason] = useState("")
  // to keep track of the current move numner 
  const [moveNumber, setMoveNumber] = useState(0)
  // current selected residence type 
  const [currentResidenceType, setCurrentResidenceType] = useState(1);


  // Dimensions //
  //const width = 500;
  //const height = 500;
  const margin = {top: 0, right: 0, bottom: 0, left: 0}
  const surveyMargin = {top: 100, right: width/5, bottom: 40, left: width/5}
  const surveyBackground = "#fff";

  // Colours 
  const lowSentimentColour = "#4a473e"
  const highSentimentColour = "#D7C598" 
  const patternColour = "black" //"#7c7766";
  const checkboxColour = "#263c41";
  // these are just for reference - the colours from other graphs 
  const colours = [ 
    "#143642", "#263c41", "#38413f", "#4a473e", 
    "#6f523b", "#815839","#a56336",'#b76935',
  ]
  const colourShelter = "#b76935"
  const colourTempResidence = "#22596D"
  const colourMainResidence = "#7c7766"


  // Graph //
  useEffect(() => {

    // if the if here is superfluous but left in case I revert to prev 
    // version where we were checking if data 
    if (sortedData) {
  
      ////////////////////////////////////
      ////////////// Data ////////////////
      ////////////////////////////////////
      
      // get the data from the index state
      const data = sortedData[dataIndex]

      // all the issues from multiple choice 
      //let allIssues = (data.data.map(d => d.issues))[0].map(issue => issue.text)
      let allIssues = [
        '温度',
        '食料や水の確保',
        '風呂',
        'トイレ',
        'プライバシーの確保',
        '生活空間の広さ',
        '災害の恐れ',
        '明るさ',
        '買い物や通院など',
        '親類や友人らと遠い',
        'におい',
        '音',
        '地域コミュニティーが希薄'
      ]

      //// Selected Issues ///
      // Start by displaying the issues for move number 0 
      // get the element for the current move number
      const elementForMoveNumber = data.data[moveNumber]
      // get issues corresponding for that element 
      let selectedIssues = _.filter(elementForMoveNumber.issues, issue => issue.selected == "yes")
      if (selectedIssues.length > 0) {
        selectedIssues = selectedIssues.map(issue => issue.text)
      } else {
        selectedIssues = [""]
      }
      // number of times this person has moved 
      const numberMoves = data.data.length

      /// Patterns ///
      const svg = d3.select(svgRef.current)
      const pattern = svg
        .append('defs')
        .append('pattern')
          .attr('id', 'pattern')
          .attr("width", 5)
          .attr("height", 5)
          .attr('patternUnits',"userSpaceOnUse")
          .append("circle")
            .attr("r", 1)
            .attr("fill", patternColour)
            .attr("fill-opacity", 0.2)

      // Reason
      if (elementForMoveNumber.reason){
        setReason(elementForMoveNumber.reason)
      }
      else{
        setReason("");
      }
  
      ////////////////////////////////////
      ///////////// Survey ///////////////
      ////////////////////////////////////  
      const t = d3.transition().duration(500).ease(d3.easeLinear);

      /// Scales ///
      // Scale for aligning text vertically 
      const scaleYText = d3.scaleBand()
          .domain(allIssues)
          .range([surveyMargin.top, height - surveyMargin.bottom])
      
      // Scale to number of selected complaints to colours 
      // where the domain is between the min and max number of issues 
      // for this specific person or between min and max complaints overall
      const numberComplaintsScale = d3.scaleLinear()
        .domain([1, 13])
        .range([0, 1])
      const colorInterpolatorNumberComplaints = d3.interpolate(highSentimentColour, lowSentimentColour)
  

      /// Survey Square with Checkboxes ///
      const g = d3.select(gRef.current)
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
  
      // Put the whole "survey" in here- i.e. background rectangle
      const surveySheet = g
          .selectAll(".survey-sheet")
          .data([0])
          .join("rect")
          .attr("class", "survey-sheet")
            .style("fill", highSentimentColour)
            //.style("fill", colorInterpolatorNumberComplaints(numberComplaintsScale(selectedIssues.length)))
            .attr("width", width)
            //.attr("height", 0)
            //.transition(t)
            .attr("height", height)
           

      // Pattern background for the survey rect
      const surveySheetPattern = g
        .selectAll(".survey-sheet-pattern")
        .data([0])
        .join("rect")
        .attr("class", "survey-sheet-pattern")
          .attr("width", width)
          .attr('fill', 'url(#pattern)')
          //.attr("height", 0)
          //.transition(t)
          .attr("height", height)

  
      // group for the content of the survey 
      const surveySheetOptions = g
          .selectAll(".overall-survey-group")
          .data([0])
          .join("g")
          .classed("overall-survey-group", true)
            //.attr("transform", `translate(${0}, ${surveyMargin.top})`)


      // The options on the survey i.e. checkbox and text - group 
      const surveySheetOptionsG = surveySheetOptions
          .selectAll(".survey-sheet-options-g")
          //.data((data.data.map(d => d.issues))[0].map(issue => issue.text))
          .data(allIssues)
          .join("g")
          .classed("survey-sheet-options-g", true)
            .attr("transform", (d, i)  => `translate(${surveyMargin.left}, ${scaleYText(d) + scaleYText.bandwidth() / 2 })`)
  
      // text with the options (complaints) 
      const surveySheetOptionsText = surveySheetOptionsG
          .selectAll(".survey-options-text")
          .data(d => [d])
          .join("text")
          .classed("survey-options-text", true)
            .text(d => d)
            .attr("dy", "0.35em")
            .style("fill", lightGrey)  
            //.style("font-size", "16px")
            .attr("text-align", "end")
            .style("text-decoration", function(d, i) {
              if (selectedIssues.includes(d)) {return "none"}
              else {return "none"}
            })
            .style("font-weight", function(d, i) {
              if (selectedIssues.includes(d)) {return "bold"}
              else {return "normal"}
            })
            .style("opacity", function(d, i) {
              if (selectedIssues.includes(d)) {return 1}
              else {return 0.5}
            })
            //.style("opacity", 0)
            //.transition(t)
            .style("opacity", 1)
            .style("font-family", 'sans-serif')
    
      // checkbox next to the text 
      const surveySheetOptionsCheckbox = surveySheetOptionsG
          .selectAll(".survey-sheet-options-checkbox")
          .data(d => [d])
          .join("rect")
          .classed("survey-sheet-options-checkbox", true)
            //.append("rect")
            .attr("width", 15)
            .attr("height", 15)
            //.attr("fill", "#f6f2e7")
            .attr("fill", currentResidenceType == 1 
            ? colourShelter 
            : currentResidenceType == 2 
            ? colourTempResidence
            : currentResidenceType == 3
            ? colourMainResidence
            : '#fff'
            )
            .attr("y", "-0.5em")
            .attr("x", -25)
            .attr("stroke", lightGrey)
            .attr("stroke-opacity", 0.5)
            .attr("stroke-width", 2)
            // 1. Change the fill in the checkboxes - fill the selected issues only 
            .attr("fill-opacity", function(d, i) {
              if (selectedIssues.includes(d)) {return 1}
              else {return 0}
            })
            .style("opacity", 1)


      ////////////////////////////////////
      ///// Shapes with move number //////
      ////////////////////////////////////  

      /// Timeline of movements ///
      const timelineG  = d3.select(timelineGRef.current)

      // Vertical scale //
      const timelineYScale = d3.scaleBand()
        .domain(_.range(9))
        .range([margin.top + surveyMargin.top, height - margin.bottom - surveyMargin.bottom])

      // 1. add groups for each shape (rect) + text on top 
      const gMoveNumber = timelineG
          .selectAll(".shape-move-number-g")
          .data(data.data)
          .join('g')
          .classed("shape-move-number-g", true)
          //.attr("transform", (d, i) => `translate(${60*i}, ${3})`)
          .attr("transform", (d, i) =>
              d.name.split("_")[1] == moveNumber
                  ? `translate(${width - surveyMargin.right*1.4}, ${timelineYScale(i)})`
                  : `translate(${width - surveyMargin.right*1.3}, ${timelineYScale(i)})`
          )
          .attr("font-weight", d => d.name.split("_")[1] == moveNumber ? "bold" : "normal")
          .on("click", function(e, datum){
            // keep track of the move number that was clicked 
            setCurrentResidenceType(datum.residence_type)
            setMoveNumber(+datum.name.split("_")[1])          
            datum.reason ? setReason(datum.reason) : setReason("")
            //setReasonToMove(elementForMoveNumber.reasonToMove)
            //setIssuesRaw(elementForMoveNumber.issuesRaw)
          }) 
          .on("mouseenter", function(e, datum) {
            // outline the clicked shape / change opacity etc 
            shapeMoveNumber
              .attr("stroke", d => d == datum ? "white" : "none")
              .attr("stroke-width", d => d == datum ? 6 : 0)
          })
          .on("mouseleave", function(e, datum) {
            shapeMoveNumber
              .attr("stroke", 'none')
              .attr("stroke-width", 0)
          })

      // 1. shape for the numbers 
      const shapeMoveNumber = gMoveNumber
        .selectAll(".shape-move-number")
        .data(d => [d])
        .join("rect")
        .classed("shape-move-number", true)
          .attr("width", width)
          .attr("height", 35)
          .attr("rx", 10)
          .attr("ry", 10)
          // colour based on type of residence         
          .attr("fill", d => 
          d.residence_type == "1.0" 
            ? colourShelter
            : d.residence_type == "2.0"
            ?  colourTempResidence
            : d.residence_type == "3.0"
            ? colourMainResidence
            : "white"
          )
          .attr("stroke-opacity", 0.5)
          .attr("opacity", 1)

      // 2. text on top of the shape 
      const textMoveNumber = gMoveNumber
        .selectAll(".text-move-number")
        .data(d => [d])
        .join("text")
        .classed("text-move-number", true)
          .style("fill", "#f6f2e7")
          .attr("transform", (d, i) => `translate(${0}, ${20})`)
          .attr("dy", "0.35em")
          .attr("dx", "0.85em")
          .attr("opacity", 1)
          .style("text-anchor", "start")
          /*
          .text((d, i) => 
            d.residence_type == "1.0" 
              ? (+d.name.split("_")[1] + 1) + " 避難所"
              : d.residence_type == "2.0"
              ? (+d.name.split("_")[1] + 1) + " 仮住まい"
              : d.residence_type == "3.0"
              ? (+d.name.split("_")[1] + 1) + " 本設住宅"
              : ""
            )
          */
         .text((d, i) => 
            d.residence_type == "1.0" 
              ? "転居　" + (+d.name.split("_")[1] + 1)
              : d.residence_type == "2.0"
              ? "転居　" + (+d.name.split("_")[1] + 1)
              : d.residence_type == "3.0"
              ? "転居　" + (+d.name.split("_")[1] + 1)
              : ""
            )
          .attr("pointer-events", "none")
          .attr("pointer", "default")

    }
  }, [dataIndex, moveNumber, width, height]);


  function getNextPerson(){
    // this is if next 
    if (dataIndex < sortedData.length - 1) {
      setDataIndex(prevState => prevState + 1)
    } else {
      setDataIndex(0)
    }
    // reset to move number 0 
    setMoveNumber(0)
    // remove the reason
    // setReason(null)
    // remove the current residence 
    setCurrentResidenceType(1)
  }

  function getPrevPerson(){
    // this is if prev 
    if (dataIndex > 0) {
      setDataIndex(prevState => prevState - 1)
    } else {
      setDataIndex(0)
    }    
    // reset to move number 0 
    setMoveNumber(0)
    // remove the reason
    // setReason(null)
    // remove the current residence 
    setCurrentResidenceType(1)
  }


  return (
    <>
      <div className="container-buttons-compalaints">

        <button onClick={getPrevPerson} className="prev-person-btn">
          <FontAwesomeIcon icon={faArrowLeft}/> 
          <FontAwesomeIcon icon={faUser}/>     
        </button>

        <div className="wrapper-survey">  
        <Legend />    
          <svg ref={svgRef} 
            width={width} 
            height={height}
          >
            <g ref={gRef}></g>
            <g ref={timelineGRef}></g>  
          </svg> 
          <div className="survey-title">{surveyTitle}</div>
        </div>

        <button onClick={getNextPerson} className="next-person-btn">
          <FontAwesomeIcon icon={faUser}/>  
          <FontAwesomeIcon icon={faArrowRight}/>    
        </button>

      </div>

      <div className="selected-text-reason">
        <div className="message">
          {
            reason ? reason : '特になし'
          }
        </div>
      </div>
    </>
  )
};

export default Survey;