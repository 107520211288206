import React, {useEffect, useState} from "react";
import SectionText from "../../Reusable/SectionText/SectionText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faWindowClose, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import TopicBoxesLetters from "./graph/TopicBoxesLetters";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";




const sectionTitle = "本設・家族の暮らしの悩み"
const SectionTextFiller = () => {
  return (
    <>
      住まいの変化による家族への影響はありましたか？
    </>
  )
}

const GraphExplanation = () => {
  return (
    <>
      <div className="normal-textbox" style={{top: "39%"}}>
        <ul>
        </ul>
      </div>
    </>
  )
}



const FamilyInfluence = ({ width, setSelectedGraphTitle }) => {

  const [revealGraphExplanation, setRevealGraphExplanation] = useState(false);

  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [])

  const toggleGraphExplanation = () => {
    setRevealGraphExplanation(!revealGraphExplanation)
  }

  return (
    <section className="section">

      <Link to={`/`}>
          <button 
            className="graph-close" 
            //onClick={() => setSelectedGraphTitle("homepage")}
          >
            <FontAwesomeIcon icon={faWindowClose}/>
          </button>  
      </Link> 

      <h3 className="section-title">{sectionTitle}</h3>

      <SectionText>
        <SectionTextFiller/>
      </SectionText>

      <Link to={"/本設へ、遺族の決断"}>
        <FontAwesomeIcon className="back-arrow" icon={faChevronLeft}/>
      </Link>
      <Link to={"/居住、滞在先での気持ち"}>
        <FontAwesomeIcon className="forward-arrow" icon={faChevronRight}/>
      </Link>

      <br/>
      <p style={{fontSize: '12px'}}>＊クリック・タップで個別の回答表示</p>
      <br/>

      <TopicBoxesLetters width={width}/>

    </section>
  )
};

export default FamilyInfluence