const data = [
  {value: "思う", count:	61},
  {value: "人",	count:	34},
  {value: "震災", count:		30},
  {value: "今", count:		29},
  {value: "亡くなる", count:		28},
  {value: "逃げる", count:		21},
  {value: "一緒", count:		20},
  {value: "孫", count:		19},
  {value: "自分", count:		17},
  {value: "暮らす", count:		17},
  {value: "避難", count:		17},
  {value: "家", count:		16},
  {value: "津波", count:		16},
  {value: "元気", count:		15},
  {value: "生きる", count:		15},
  {value: "母", count:	15},
  {value: "妻", count:	15},
  {value: "ほしい", count: 15},
  {value: "夫", count:	14},
  {value: "思い", count:	14},
  {value: "年", count:	14},
  {value: "良い", count:	13},
  {value: "感謝", count:	13},
  {value: "生活", count:	13},
  {value: "気持ち", count: 13},
  {value: "時", count:	13},
  {value: "伝える", count:	12},
  {value: "前", count:	12},
  {value: "家族", count:	11},
  {value: "亡くす", count:	11},
  {value: "見つかる", count:	11},
  {value: "子ども", count:	10},
  {value: "父さん", count:	10},
  {value: "戻る", count:	10},
  {value: "安心", count:	10},
  {value: "犠牲", count:	9},
  {value: "助ける", count: 9},
  {value: "行く", count:	9},
  {value: "分かる", count:	9},
  {value: "頑張る", count:	9},
  {value: "父", count:	8},
  {value: "ずっと", count:	8},
  {value: "娘", count:	8},
  {value: "自宅", count:	8},
  {value: "言う", count:	8},
  {value: "忘れる", count:	8},
  {value: "つらい", count:	8},
  {value: "息子", count:	8},
  {value: "仕事", count: 7},
  {value: "よい", count: 7},
  {value: "守る", count: 7},
  {value: "成長", count: 7},
  {value: "楽しい", count: 7},
  {value: "悪い", count: 7},
  {value: "落ちる", count: 7},
  {value: "残念", count: 7},
  {value: "早い", count: 7},
  {value: "みんな", count: 7},
  {value: "手", count: 7},
  {value: "いつも", count: 7},
  {value: "死ぬ", count: 7},

]

/// This is just for the missing people data - top 70 currently ///
const dataMissing = [
  {value: "思う", count:82},
  {value: "人", count:69},
  {value: "震災", count:59},
  {value: "亡くなる", count:53},
  {value: "今", count:46},
  {value: "母", count:37},
  {value: "家", count:35},
  {value: "自分", count:33},
  {value: "年", count:29},
  {value: "見つかる", count:29},
  {value: "時", count:27},
  {value: "見る", count:27},
  {value: "夫", count:26},
  {value: "津波", count:22},
  {value: "考える", count:22},
  {value: "子ども", count:21},
  {value: "父", count:21},
  {value: "一緒", count:21},
  {value: "言う", count:20},
  {value: "息子", count:20},
  {value: "前", count:19},
  {value: "妻", count:19},
  {value: "思い", count:19},
  {value: "家族", count:19},
  {value: "当時", count:17},
  {value: "ほしい", count:17},
  {value: "避難", count:16},
  {value: "行く", count:16},
  {value: "伝える", count:16},
  {value: "逃げる", count:16},
  {value: "出る", count:15},
  {value: "後", count:14},
  {value: "生活", count:14},
  {value: "生きる", count:14},
  {value: "孫", count:14},
  {value: "暮らす", count:14},
  {value: "仏壇", count:13},
  {value: "毎日", count:13},
  {value: "自宅", count:13},
  {value: "建てる", count:13},
  {value: "2人", count:13},
  {value: "手", count:13},
  {value: "守る", count:12},
  {value: "日", count:12},
  {value: "合わせる", count:12},
  {value: "仕事", count:12},
  {value: "亡くす", count:11},
  {value: "長男", count:11},
  {value: "早い", count:11},
  {value: "行方", count:11},
  {value: "夢", count:11},
  {value: "少し", count:11},
  {value: "元気", count:11},
  {value: "再建", count:11},
  {value: "良い", count:11},
  {value: "両親" , count: 10},
  {value: "会う" , count: 10},
  {value: "しれる" , count: 10},
  {value: "分かる" , count: 10},
  {value: "不明" , count: 10},
  {value: "気持ち" , count: 10},
  {value: "みんな" , count: 10},
  {value: "帰る" , count: 10},
  {value: "助かる" , count: 10},
  {value: "感謝" , count: 9},
  {value: "来る" , count: 9},
  {value: "地域" , count: 9},
  {value: "いろいろ" , count: 9},
  {value: "楽しい" , count: 9},
  {value: "なくなる" , count: 8},
]

/// This is just for the no missing people data - top 70 currently ///
const dataNoMissing = [
  {value: "思う", count: 284},
  {value: "人", count: 157},
  {value: "亡くなる", count: 140},
  {value: "震災", count: 140},
  {value: "今", count: 128},
  {value: "自分", count: 110},
  {value: "生きる", count: 88},
  {value: "妻", count: 86},
  {value: "年", count: 78},
  {value: "津波", count: 77},
  {value: "母", count: 75},
  {value: "行く", count: 75},
  {value: "時", count: 75},
  {value: "逃げる", count: 70},
  {value: "見る", count: 68},
  {value: "家", count: 67},
  {value: "思い", count: 62},
  {value: "一緒", count: 62},
  {value: "言う", count: 61},
  {value: "夫", count: 61},
  {value: "日", count: 59},
  {value: "前", count: 59},
  {value: "後", count: 58},
  {value: "孫", count: 54},
  {value: "気持ち", count: 53},
  {value: "避難", count: 53},
  {value: "考える", count: 49},
  {value: "父", count: 47},
  {value: "娘", count: 45},
  {value: "ほしい", count: 42},
  {value: "子ども", count: 42},
  {value: "死ぬ", count: 41},
  {value: "亡くす", count: 41},
  {value: "月", count: 40},
  {value: "家族", count: 39},
  {value: "来る", count: 36},
  {value: "息子", count: 36},
  {value: "見つかる", count: 36},
  {value: "良い", count: 35},
  {value: "仕事", count: 35},
  {value: "生活", count: 34},
  {value: "守る", count: 34},
  {value: "自宅", count: 34},
  {value: "助ける", count: 33},
  {value: "感謝", count: 29},
  {value: "話す", count: 29},
  {value: "伝える", count: 29},
  {value: "犠牲", count: 29},
  {value: "元気", count: 28},
  {value: "戻る", count: 27},
  {value: "もっと", count: 27},
  {value: "しれる", count: 27},
  {value: "話", count: 27},
  {value: "歳", count: 26},
  {value: "ずっと", count: 26},
  {value: "暮らす", count: 26},
  {value: "時間", count: 25},
  {value: "分かる", count: 25},
  {value: "聞く", count: 24},
  {value: "流す", count: 24},
  {value: "つらい", count: 24},
  {value: "地震", count: 24},
  {value: "中", count: 24},
  {value: "助かる", count: 24},
  {value: "早い", count: 24},
  {value: "最後", count: 23},
  {value: "よい", count: 23},
  {value: "みんな", count: 23},
  {value: "たつ", count: 23},
  {value: "父さん", count: 22},

]

export { data, dataMissing, dataNoMissing };